import {
  Col,
  Descriptions,
  Drawer,
  notification,
  Row,
  Button,
  Switch,
  Table,
  Tabs,
  Typography,
  Tooltip,
  Popconfirm,
  Modal,
  Input
} from 'antd';
import { Comment } from '@ant-design/compatible';
import Barcode from 'react-barcode';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Avatar from 'antd/lib/avatar/avatar';
import { Formik } from 'formik';
import is from 'is_js';
import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { editUserAtom, profileAtom, reloadCustomers, userRolesAtom } from '../../../atoms/Atoms';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import api from '../../../api/api';

function EditUser() {
  const [user, setUser] = useRecoilState(editUserAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile.contents.roles.includes('SuperAdmin');
  const isCustomerService =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('CustomerSupportAdmin') ||
        profile?.contents?.roles?.includes('CustomerSupport')));
  const isStoreManager =
    isAdmin ||
    isCustomerService ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('StoreManager') ||
        profile?.contents?.roles?.includes('StoreAdmin')));
  const [reload, setReload] = useRecoilState(reloadCustomers);
  const userRole = useRecoilValue(userRolesAtom);
  const roleList = ['User', 'Admin', 'Delivery', 'Dispatch', 'StoreAdmin', 'StoreManager', 'SuperAdmin', 'Picker', 'Packer', 'UserAdmin',
    'TopAnalytics', 'EBT', 'DeliveryProvider', 'DeliNotifications', 'Deli', 'Marketing', 'SelfCheckoutManager', 'CustomerSupport',
    'FreshFoodManager', 'PhonePicker', 'Kitchen', 'DeliManager', 'Finance', 'Merchandiser', 'Demo', 'CustomerSupportAdmin', 'Owner', 'Inventory'];
  const roles = roleList.map((r, index) => ({ roleId: index, role: r, assigned: false }));
  const sortedRoles = roles.sort((a, b) => a.role.localeCompare(b.role));
  const { t } = useTranslation();
  const [resettingPassword, setResettingPassword] = useState(false);
  const [deletingUser, setDeletingUser] = useState(false);
  const [gettingIdCode, setGettingIdCode] = useState(false);
  const [managerCode, setManagerCode] = useState(null);
  const [gettingLoginCode, setGettingLoginCode] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [generatingNewCode, setGeneratingNewCode] = useState(false);
  const width = window.innerWidth;

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  function setRoles() {
    for (let i = 0; i < userRole.length; i++) {
      for (let j = 0; j < sortedRoles.length; j++) {
        if (userRole[i] === sortedRoles[j].role) {
          sortedRoles[j].assigned = true;
        }
      }
    }
  }

  function deleteUser() {
    return isAdmin ? (
      <div className="flex space-x-3 mt-5">
        <Button
          className="w-full md:w-full flex items-center justify-center text-center text-xs transition delay-150 duration-300 ease-in-out font-semibold px-6 py-6 rounded-full"
          type="primary"
          size="small"
          loading={deletingUser}
          onClick={() => {
            setDeletingUser(true);
            api
              .post(
                `admin/deleteuser/${user.friendCode}`,
                { }
              )
              .then((response) => {
                setDeletingUser(false);
                showMessage(response.data.message);
              })
              .catch((error) => {
                setDeletingUser(false);
                console.error(error);
              });
          }}
        >
          {t('delete_user')}
        </Button>
      </div>
    ) : <></>;
  }

  function managerIdCode() {
    return isStoreManager ? (
      <div className="flex space-x-3 mt-5">
        <Button
          className="w-full md:w-full flex items-center justify-center text-center text-xs transition delay-150 duration-300 ease-in-out font-semibold px-6 py-6 rounded-full"
          type="primary"
          size="small"
          loading={gettingIdCode}
          onClick={() => {
            setGettingIdCode(true);
            api
              .get(`admin/getmanagercode/${user.id}`)
              .then((response) => {
                setGettingIdCode(false);
                setManagerCode({
                  userId: user.id,
                  code: response.data.data,
                  name: `${user.firstName} ${user.lastName}`
                });
              })
              .catch((error) => {
                setGettingIdCode(false);
                console.error(error);
              });
          }}
        >
          {t('get_manager_code')}
        </Button>
      </div>
    ) : <></>;
  }

  function loginCode() {
    return isStoreManager ? (
      <div className="space-y-5 mt-5">
        <Button
          className="w-full md:w-full flex items-center justify-center text-center text-xs transition delay-150 duration-300 ease-in-out font-semibold px-6 py-6 rounded-full"
          type="primary"
          size="small"
          loading={gettingLoginCode}
          onClick={() => {
            setGettingLoginCode(true);
            api
              .get(`cs/users/logincode/${user.id}`)
              .then((response) => {
                setGettingLoginCode(false);
                if (response.data.success) {
                  showMessage(`${response.data.message}. (${t('copied_to_clipboard')})`);
                  navigator.clipboard.writeText(response.data.message);
                } else {
                  showMessage(`${response.data.error}`);
                }
              })
              .catch((error) => {
                setGettingLoginCode(false);
                console.error(error);
              });
          }}
        >
          {t('send_login_code')}
        </Button>
        <Button
          className="w-full md:w-full flex items-center justify-center text-center text-xs transition delay-150 duration-300 ease-in-out font-semibold px-6 py-6 rounded-full"
          type="primary"
          size="small"
          loading={gettingLoginCode}
          onClick={() => {
            setGettingLoginCode(true);
            api
              .get(`cs/users/reset/logincode/${user.id}`)
              .then((response) => {
                setGettingLoginCode(false);
                if (response.data.success) {
                  showMessage(`${response.data.message}. (${t('copied_to_clipboard')})`);
                  navigator.clipboard.writeText(response.data.message);
                } else {
                  showMessage(`${response.data.error}`);
                }
              })
              .catch((error) => {
                setGettingLoginCode(false);
                console.error(error);
              });
          }}
        >
          {t('reset_login_code')}
        </Button>
      </div>
    ) : <></>;
  }

  function resetPasswordRandom() {
    return (
      <div className="flex space-x-3 mt-10">
        <Popconfirm
          title={t('reset_password_prompt')}
          okText={t('yes')}
          cancelText={t('no')}
          onConfirm={() => {
            setResettingPassword(true);
            api
              .put(
                `cs/users/createdefaultpassword/${user.id}/true`,
                { }
              )
              .then((response) => {
                setResettingPassword(false);
                showMessage(`${response.data.message}. (${t('copied_to_clipboard')})`);
                navigator.clipboard.writeText(response.data.message);
              })
              .catch((error) => {
                setResettingPassword(false);
                console.error(error);
              });
          }}
        >
          <Button
            className="w-full md:w-full flex items-center justify-center text-center text-xs transition delay-150 duration-300 ease-in-out font-semibold px-6 py-6 rounded-full"
            type="primary"
            size="small"
            loading={resettingPassword}
          >
            {t('reset_password')} ({t('random')})
          </Button>
        </Popconfirm>
      </div>
    );
  }

  function resetPasswordCustom() {
    return (
      <div className="flex space-x-3 mt-5">
        <Button
          className="w-full md:w-full flex items-center justify-center text-center text-xs transition delay-150 duration-300 ease-in-out font-semibold px-6 py-6 rounded-full"
          type="primary"
          size="small"
          onClick={() => setShowPasswordChange(true)}
          loading={resettingPassword}
        >
          {t('reset_password')} ({t('custom')})
        </Button>
      </div>
    );
  }

  function updatePassword(val) {
    if (!val) {
      showMessage(t('password_cannot_by_empty'));
      return;
    }
    setResettingPassword(true);
    api
      .put('cs/users/changepassword', {
        UserId: user.id,
        Password: val
      })
      .then((response) => {
        setResettingPassword(false);
        if (response.data.message) {
          showMessage(response.data.message);
          setShowPasswordChange(false);
        }
        if (response.data.error) {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setResettingPassword(false);
        console.error(error);
      });
  }

  function prepareRolesForm() {
    return (
      <div>
        <Table
          size="small"
          bordered
          pagination={false}
          columns={[
            {
              title: t('role'),
              dataIndex: 'role',
              key: 'role',
              align: 'left',
              className: 'text-xs',
              width: '70%',
              render: (text) => <Typography.Text>{text}</Typography.Text>
            },
            {
              title: t('assigned'),
              key: 'assigned',
              dataIndex: 'assigned',
              align: 'center',
              width: '30%',
              render: (text, record) => (
                <Switch
                  size="small"
                  defaultChecked={record.assigned}
                  disabled={record.role === 'Owner' ? !isAdmin : !isStoreManager}
                  onChange={() => {
                    api
                      .put(
                        `users/userroles/${user.id}/${record.role}`,
                        { }
                      )
                      .then((response) => {
                        if (response && response.data.error) {
                          console.error('Error Message: ', response.data.error);
                          notification.open({
                            message: 'Alert!',
                            description: (
                              <Comment
                                author={<span>Grocefy</span>}
                                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                                content={
                                  <p className="text-sm">{response.data.error}</p>
                                }
                              />
                            ),
                          });
                        } else if (
                          response &&
                          response.data.success &&
                          response.data.message
                        ) {
                          notification.open({
                            message: 'Success!',
                            description: (
                              <Comment
                                author={<span>Grocefy</span>}
                                avatar={<Avatar src={Grocefy} alt="grocefy" />}
                                content={
                                  <p className="text-sm">{response.data.message}</p>
                                }
                              />
                            ),
                          });
                        }
                        setRoles();
                        setReload(!reload);
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  }}
                />
              ),
            },
          ]}
          dataSource={sortedRoles}
        />
      </div>
    );
  }

  function prepareCommunicationsForm() {
    if (user == null) {
      return <div>No Data Loaded</div>;
    }
    return (
      <div className="my-4">
        <Descriptions>
          <Descriptions.Item className="w-full" label={t('allows_email')}>
            <Switch
              defaultChecked={user.allowEmails}
              onChange={(checked) =>
                api
                  .post(
                    `cs/update/user/${user.id}`,
                    {
                      AllowEmails: checked,
                    }
                  )
                  .then((response) => {
                    if (response && response.data.error) {
                      console.error('Error Message: ', response.data.error);
                      notification.open({
                        message: 'Alert!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.error}</p>
                            }
                          />
                        ),
                      });
                    } else if (
                      response &&
                      response.data.success &&
                      response.data.message
                    ) {
                      notification.open({
                        message: 'Success!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.message}</p>
                            }
                          />
                        ),
                      });
                    }
                    setReload(!reload);
                  })
                  .catch((error) => {
                    console.error(error);
                  })}
            />
          </Descriptions.Item>
          <br />
          <br />
          <Descriptions.Item className="w-full" label={t('allow_push')}>
            <Switch
              defaultChecked={user.allowPush}
              onChange={(checked) =>
                api
                  .post(
                    `cs/update/user/${user.id}`,
                    {
                      AllowPush: checked,
                    }
                  )
                  .then((response) => {
                    if (response && response.data.error) {
                      console.error('Error Message: ', response.data.error);
                      notification.open({
                        message: 'Alert!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.error}</p>
                            }
                          />
                        ),
                      });
                    } else if (
                      response &&
                      response.data.success &&
                      response.data.message
                    ) {
                      notification.open({
                        message: 'Success!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.message}</p>
                            }
                          />
                        ),
                      });
                    }
                    setReload(!reload);
                  })
                  .catch((error) => {
                    console.error(error);
                  })}
            />
          </Descriptions.Item>
          <br />
          <br />
          <Descriptions.Item className="w-full" label={t('allow_sms')}>
            <Switch
              defaultChecked={user.allowSMS}
              onChange={(checked) =>
                api
                  .post(
                    `cs/update/user/${user.id}`,
                    {
                      AllowSMS: checked,
                    }
                  )
                  .then((response) => {
                    if (response && response.data.error) {
                      console.error('Error Message: ', response.data.error);
                      notification.open({
                        message: 'Alert!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.error}</p>
                            }
                          />
                        ),
                      });
                    } else if (
                      response &&
                      response.data.success &&
                      response.data.message
                    ) {
                      notification.open({
                        message: 'Success!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.message}</p>
                            }
                          />
                        ),
                      });
                    }
                    setReload(!reload);
                  })
                  .catch((error) => {
                    console.error(error);
                  })}
            />
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }

  function preparePermissionsForm() {
    if (user == null) {
      return <div>No Data Loaded</div>;
    }
    return (
      <div>
        <Descriptions>
          <Descriptions.Item className="w-full" label={t('is_blocked')}>
            <Switch
              checked={user.isBlocked}
              onChange={(checked) =>
                api
                  .post(
                    `cs/update/user/${user.id}`,
                    {
                      IsBlocked: checked,
                    }
                  )
                  .then((response) => {
                    if (response && response.data.error) {
                      console.error('Error Message: ', response.data.error);
                      notification.open({
                        message: 'Alert!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.error}</p>
                            }
                          />
                        ),
                      });
                    } else if (
                      response &&
                      response.data.success &&
                      response.data.message
                    ) {
                      notification.open({
                        message: 'Success!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.message}</p>
                            }
                          />
                        ),
                      });
                    }
                    setReload(!reload);
                  })
                  .catch((error) => {
                    console.error(error);
                  })}
            />
          </Descriptions.Item>
          <br />
          <br />
          <Descriptions.Item className="w-full" label={t('is_guest_user')}>
            <Switch
              checked={user.isGuestUser}
              onChange={(checked) =>
                api
                  .post(
                    `cs/update/user/${user.id}`,
                    {
                      IsGuestUser: checked,
                    }
                  )
                  .then((response) => {
                    if (response && response.data.error) {
                      console.error('Error Message: ', response.data.error);
                      notification.open({
                        message: 'Alert!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.error}</p>
                            }
                          />
                        ),
                      });
                    } else if (
                      response &&
                      response.data.success &&
                      response.data.message
                    ) {
                      notification.open({
                        message: 'Success!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.message}</p>
                            }
                          />
                        ),
                      });
                    }
                    setReload(!reload);
                  })
                  .catch((error) => {
                    console.error(error);
                  })}
            />
          </Descriptions.Item>
          <br />
          <br />
          <Descriptions.Item className="w-full" label={t('view_beta_stores')}>
            <Switch
              defaultChecked={user.canViewBetaStores}
              onChange={(checked) =>
                api
                  .post(
                    `cs/update/user/${user.id}`,
                    {
                      CanViewBetaStores: checked,
                    }
                  )
                  .then((response) => {
                    if (response && response.data.error) {
                      console.error('Error Message: ', response.data.error);
                      notification.open({
                        message: 'Alert!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.error}</p>
                            }
                          />
                        ),
                      });
                    } else if (
                      response &&
                      response.data.success &&
                      response.data.message
                    ) {
                      notification.open({
                        message: 'Success!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.message}</p>
                            }
                          />
                        ),
                      });
                    }
                    setReload(!reload);
                  })
                  .catch((error) => {
                    console.error(error);
                  })}
            />
          </Descriptions.Item>
          <br />
          <br />
          <Descriptions.Item className="w-full" label={t('view_alpha_stores')}>
            <Switch
              defaultChecked={user.canViewAlphaStores}
              onChange={(checked) =>
                api
                  .post(
                    `cs/update/user/${user.id}`,
                    {
                      CanViewAlphaStores: checked,
                    }
                  )
                  .then((response) => {
                    if (response && response.data.error) {
                      console.error('Error Message: ', response.data.error);
                      notification.open({
                        message: 'Alert!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.error}</p>
                            }
                          />
                        ),
                      });
                    } else if (
                      response &&
                      response.data.success &&
                      response.data.message
                    ) {
                      notification.open({
                        message: 'Success!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.message}</p>
                            }
                          />
                        ),
                      });
                    }
                    setReload(!reload);
                  })
                  .catch((error) => {
                    console.error(error);
                  })}
            />
          </Descriptions.Item>
          <br />
          <br />
          <Descriptions.Item className="w-full" label={t('can_make_orders')}>
            <Switch
              defaultChecked={user.canMakeOrders}
              onChange={(checked) =>
                api
                  .post(
                    `cs/update/user/${user.id}`,
                    {
                      CanMakeOrders: checked,
                    }
                  )
                  .then((response) => {
                    if (response && response.data.error) {
                      console.error('Error Message: ', response.data.error);
                      notification.open({
                        message: 'Alert!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.error}</p>
                            }
                          />
                        ),
                      });
                    } else if (
                      response &&
                      response.data.success &&
                      response.data.message
                    ) {
                      notification.open({
                        message: 'Success!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.message}</p>
                            }
                          />
                        ),
                      });
                    }
                    setReload(!reload);
                  })
                  .catch((error) => {
                    console.error(error);
                  })}
            />
          </Descriptions.Item>
          <br />
          <br />
          <Descriptions.Item className="w-full" label={t('view_beta_infocards')}>
            <Switch
              defaultChecked={user.canMakeOrders}
              onChange={(checked) =>
                api
                  .post(
                    `cs/update/user/${user.id}`,
                    {
                      CanViewBetaInfoCards: checked,
                    }
                  )
                  .then((response) => {
                    if (response && response.data.error) {
                      console.error('Error Message: ', response.data.error);
                      notification.open({
                        message: 'Alert!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.error}</p>
                            }
                          />
                        ),
                      });
                    } else if (
                      response &&
                      response.data.success &&
                      response.data.message
                    ) {
                      notification.open({
                        message: 'Success!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.message}</p>
                            }
                          />
                        ),
                      });
                    }
                    setReload(!reload);
                  })
                  .catch((error) => {
                    console.error(error);
                  })}
            />
          </Descriptions.Item>
          <br />
          <br />
          <Descriptions.Item className="w-full" label={t('is_high_risk')}>
            <Switch
              defaultChecked={user.isHighRisk}
              onChange={(checked) =>
                api
                  .post(
                    `cs/update/user/${user.id}`,
                    {
                      CanMakeOrders: checked,
                    }
                  )
                  .then((response) => {
                    if (response && response.data.error) {
                      console.error('Error Message: ', response.data.error);
                      notification.open({
                        message: 'Alert!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.error}</p>
                            }
                          />
                        ),
                      });
                    } else if (
                      response &&
                      response.data.success &&
                      response.data.message
                    ) {
                      notification.open({
                        message: 'Success!',
                        description: (
                          <Comment
                            author={<span>Grocefy</span>}
                            avatar={<Avatar src={Grocefy} alt="grocefy" />}
                            content={
                              <p className="text-sm">{response.data.message}</p>
                            }
                          />
                        ),
                      });
                    }
                    setReload(!reload);
                  })
                  .catch((error) => {
                    console.error(error);
                  })}
            />
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }

  function prepareGeneralForm() {
    if (user == null) {
      return <div>No Data Loaded</div>;
    }

    return (
      <div>
        <Formik
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            gender: user.gender,
            preferredLanguage: user.preferredLanguage,
            birthDate: user.birthDate,
            canViewBetaStores: user.canViewBetaStores,
            canViewAlphaStores: user.canViewAlphaStores,
            canMakeOrders: user.canMakeOrders,
            canViewBetaInfoCards: user.canViewBetaInfoCards,
            isHighRisk: user.isHighRisk,
            friendCode: user.friendCode,
            externalClientId: user.externalClientId
          }}
          validate={(values) => {
            const errors = {};
            if (is.empty(values.firstName)) {
              errors.firstName = t('name_required');
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            // const value = {
            //   FirstName: values.firstName,
            //   LastName: values.lastName,
            //   Email: values.email,
            //   PhoneNumber: values.phoneNumber,
            //   Gender: parseInt(values.gender, 10),
            //   BirthDate: values.birthDate,
            //   PreferredLanguage: parseInt(values.preferredLanguage, 10),
            // };
            api
              .post(
                `cs/update/user/${user.id}`,
                {
                  FirstName: values.firstName,
                  LastName: values.lastName,
                  Email: values.email,
                  PhoneNumber: values.phoneNumber,
                  BirthDate: values.birthDate,
                  Gender: parseInt(values.gender, 10),
                  PreferredLanguage: parseInt(values.preferredLanguage, 10),
                  ExternalClientId: values.externalClientId
                }
              )
              .then((response) => {
                if (response && response.data.error) {
                  console.error('Error Message: ', response.data.error);
                  notification.open({
                    message: 'Alert!',
                    description: (
                      <Comment
                        author={<span>Grocefy</span>}
                        avatar={<Avatar src={Grocefy} alt="grocefy" />}
                        content={
                          <p className="text-sm">{response.data.error}</p>
                        }
                      />
                    ),
                  });
                } else if (
                  response &&
                  response.data.success &&
                  response.data.message
                ) {
                  notification.open({
                    message: 'Success!',
                    description: (
                      <Comment
                        author={<span>Grocefy</span>}
                        avatar={<Avatar src={Grocefy} alt="grocefy" />}
                        content={
                          <p className="text-sm">{response.data.message}</p>
                        }
                      />
                    ),
                  });
                }
                setSubmitting(false);
                setUser(null);
                setReload(!reload);
              })
              .catch((error) => {
                console.error(error);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Row>
                <Col span={10}>
                  <div className="my-4">
                    <div className="font-semibold text-md tracking-tight mb-1">
                      {t('name')}
                    </div>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="i.e. John"
                      className="text-gray-900 text-sm rounded p-2 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                      value={values.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName && touched.firstName && (
                      <div className="w-full font-semibold text-xs text-red-400 mt-1">
                          {errors.firstName}
                      </div>
                    )}
                  </div>
                </Col>
                <br />
                <Col span={10} offset={4}>
                  <div className="my-4">
                    <div className="font-semibold text-md tracking-tight mb-1">
                      {t('last_name')}
                    </div>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      placeholder="i.e. John"
                      className="text-gray-900 text-sm rounded p-2 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                      value={values.lastName}
                      onChange={handleChange}
                    />
                    {errors.lastName && touched.lastName && (
                      <div className="w-full font-semibold text-xs text-red-400 mt-1">
                          {errors.lastName}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <div className="my-4">
                <div className="font-semibold text-md tracking-tight mb-1">
                  {t('email')}
                </div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  autoCapitalize="true"
                  placeholder="i.e. johndoe@mail.com"
                  className="text-gray-900 text-sm rounded p-2 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  value={values.email}
                  onChange={handleChange}
                />
                {errors.email && touched.email && (
                  <div className="w-full font-semibold text-xs text-red-400 mt-1">
                    {errors.email}
                  </div>
                )}
              </div>
              <div className="my-4">
                <div className="font-semibold tracking-tight mb-2">
                  {t('phone')}
                </div>
                <input
                  required
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  onChange={handleChange}
                  value={values.phoneNumber}
                  placeholder="787 777 7777"
                  className="text-gray-900 text-sm rounded p-3 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                />
                {errors.phoneNumber && touched.phoneNumber && (
                <div className="w-full font-semibold text-xs text-rose-400 mt-1">
                  {errors.phoneNumber}
                </div>
                )}
              </div>
              <div className="my-4">
                <div className="font-semibold tracking-tight mb-2">
                  {t('birth_date')}
                </div>
                <input
                  required
                  id="birthDate"
                  name="birthDate"
                  type="date"
                  onChange={handleChange}
                  value={values.birthDate}
                  className="text-gray-900 text-sm rounded p-3 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                />
              </div>
              <Row>
                <Col span={10}>
                  <div className="my-4">
                    <div className="font-semibold tracking-tight mb-1">
                      {t('gender')}
                    </div>
                    <select
                      name="gender"
                      id="gender"
                      value={values.gender}
                      onChange={handleChange}
                    >
                      <option value={5} disabled>Select gender...</option>
                      <option value="0">Male</option>
                      <option value="1">Female</option>
                      <option value="2">Prefer not to answer</option>
                    </select>
                    {errors.gender && touched.gender && (
                      <div className="w-full font-semibold text-xs text-red-400 mt-1">
                        {errors.gender}
                      </div>
                    )}
                  </div>
                </Col>
                <Col span={10} offset={4}>
                  <div className="my-4">
                    <div className="font-semibold tracking-tight mb-1">
                      {t('language')}
                    </div>
                    <select
                      name="preferredLanguage"
                      id="preferredLanguage"
                      value={values.preferredLanguage}
                      onChange={handleChange}
                    >
                      <option value={5} disabled>Select language...</option>
                      <option value={0}>{t('spanish')}</option>
                      <option value={1}>{t('english')}</option>
                    </select>
                    {errors.preferredLlanguage && touched.preferredLanguage && (
                      <div className="w-full font-semibold text-xs text-red-400 mt-1">
                        {errors.preferredLanguage}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <div className="my-4">
                <div className="font-semibold text-md tracking-tight mb-1">
                  {t('external_id')}
                </div>
                <input
                  type="text"
                  name="externalClientId"
                  id="externalClientId"
                  className="text-gray-900 text-sm rounded p-2 w-full appearance-none ring-0 focus:ring-0 border border-gray-200 focus:border-gray-300 outline-none focus:outline-none transition ease-in-out duration-500"
                  value={values.externalClientId}
                  onChange={handleChange}
                />
              </div>
              <div className="flex space-x-3 mt-10">
                <button
                  className="w-full md:w-1/2 text-xs transition delay-150 duration-300 ease-in-out border border-gray-700 hover:border-red-600 text-gray-500 hover:text-red-400 font-semibold px-6 py-3 rounded-full"
                  type="button"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setUser(null);
                  }}
                >
                  {t('cancel')}
                </button>
                <button
                  size="small"
                  className="w-full md:w-1/2 flex items-center justify-center text-center text-xs transition delay-150 duration-300 ease-in-out bg-blue-500 hover:bg-blue-600 text-white font-semibold px-6 py-3 rounded-full"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {' '}
                  {isSubmitting && (
                    <div>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    </div>
                  )}
                  {t('save')}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }

  useEffect(() => {
    setNewPassword('');
  }, [setShowPasswordChange]);

  return (
    <div>
      <Drawer
        title={t('edit_users')}
        placement="right"
        closable
        onClose={() => setUser(null)}
        open={editUserAtom}
        width={width < 1024 ? width : 500}
      >
        <div>
          <Tabs>
            <Tabs.TabPane key={1} tab="General">
              <div>{prepareGeneralForm()}</div>
            </Tabs.TabPane>
            <Tabs.TabPane key={2} tab={t('communication')}>
              <div>{prepareCommunicationsForm()}</div>
            </Tabs.TabPane>
            <Tabs.TabPane key={3} tab={t('permissions')}>
              <div>{preparePermissionsForm()}</div>
            </Tabs.TabPane>
            <Tabs.TabPane key={4} tab="Roles">
              {setRoles()}
              {prepareRolesForm()}
            </Tabs.TabPane>
            <Tabs.TabPane key={5} tab={t('access')}>
              {resetPasswordRandom()}
              {resetPasswordCustom()}
              {deleteUser()}
              {loginCode()}
              {managerIdCode()}
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Drawer>

      {managerCode && (
        <Modal
          title={
            <div className="flex">
              <div>{t('manager_code')} - </div>
              <Typography.Text
                copyable
                className="ml-1"
                style={{ marginTop: 2 }}
              >
                {managerCode.code}
              </Typography.Text>
            </div>
          }
          open={managerCode}
          onClose={() => setManagerCode(null)}
          footer={[
            <Button
              key="close"
              type="primary"
              disabled={generatingNewCode}
              onClick={() => {
                setManagerCode(null);
              }}
            >
              {t('close')}
            </Button>,
            <Button
              key="print"
              type="primary"
              disabled={generatingNewCode}
              onClick={() => {
                const printContents = document.getElementById('divtoprint').innerHTML;
                const pop = window.open();
                pop.document.body.innerHTML = printContents;
                pop.print();
              }}
            >
              {t('print')}
            </Button>,
            <>
              {isAdmin && (
                <Popconfirm
                  title={t('generate_new_code_prompt')}
                  okText={t('yes')}
                  cancelText={t('no')}
                  onConfirm={() => {
                    setGeneratingNewCode(true);
                    api.post(`admin/generatenewmanagercode/${managerCode.userId}`, { }).then((response) => {
                      setGeneratingNewCode(false);
                      if (response.data.success) {
                        setManagerCode({
                          ...managerCode,
                          code: response.data.data
                        });
                      }
                      showMessage(t('new_manager_code_created'));
                    }).catch((error) => {
                      setGeneratingNewCode(false);
                      showMessage(error.message);
                    });
                  }}
                >
                  <Button
                    key="generate"
                    type="primary"
                    loading={generatingNewCode}
                  >
                    {t('generate_new_code')}
                  </Button>
                </Popconfirm>
              )}
            </>
          ]}
        >
          <div
            id="divtoprint"
            className="mx-auto"
            style={{
              display: 'table'
            }}
          >
            <div style={{
              width: 210,
              textAlign: 'center',
              fontWeight: 'bold',
              padding: 0,
              margin: 0
            }}
            >
              {managerCode.name}
            </div>
            <Barcode
              width={2}
              value={managerCode.code}
              format="EAN13"
              displayValue={false}
            />
          </div>
        </Modal>
      )}

      <Modal
        title={t('change_password')}
        open={showPasswordChange}
        onClose={() => setShowPasswordChange(false)}
        footer={[
          <Button
            key="close"
            type="primary"
            onClick={() => setShowPasswordChange(false)}
          >
            {t('close')}
          </Button>
        ]}
      >
        <Input.Search
          loading={resettingPassword}
          enterButton={t('apply')}
          size="large"
          value={newPassword}
          onChange={(obj) => setNewPassword(obj.target.value)}
          onSearch={(value) => updatePassword(value)}
        />
      </Modal>
    </div>
  );
}

export default EditUser;
