import React, { useState, useEffect } from 'react';
import { useMount } from 'react-use';
import { useSetRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';
import { useJsonToCsv } from 'react-json-csv';
import {
  Button,
  Table,
  Select,
  Dropdown,
  DatePicker,
  Input,
  Modal,
  Menu,
  Switch,
  InputNumber,
  Popconfirm,
  Tag,
  notification,
  Avatar,
  Tooltip,
  Popover,
  Upload
} from 'antd';
import dayjs from 'dayjs';
import { PageHeader } from '@ant-design/pro-layout';
import { Comment } from '@ant-design/compatible';
import {
  CheckCircleOutlined,
  DownSquareOutlined,
  InboxOutlined,
  PlusOutlined,
  ReloadOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { timezoneAtom, profileAtom, businessAtom } from '../../../atoms/Atoms';
import { thousandv2, toCurrency, renderShortDateTime, renderDate } from '../../utils/functions';
import api from '../../../api/api';
import envConfig from '../../../envConfig';
import { dateRangePresets } from '../../utils/utils';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import { businessesSelector } from '../../../atoms/Selectors';

function Invoices() {
  const uploadModalDefaultState = {
    type: 0,
    fileData: null,
    approve: false,
    applyPrice: false,
    applyCost: false,
    applyMovement: false,
    businessId: '',
    open: false
  };
  const width = window.innerWidth;
  const { RangePicker } = DatePicker;
  const { Dragger } = Upload;
  const { t } = useTranslation();
  const { Option } = Select;
  const businesses = useRecoilValueLoadable(businessesSelector);
  const business = useRecoilValue(businessAtom);
  const timezone = useRecoilValue(timezoneAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [currentSize, setCurrentSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [invoicesData, setInvoicesData] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedInvoiceData, setSelectedInvoiceData] = useState(null);
  const [selectedInvoiceItems, setSelectedInvoiceItems] = useState([]);
  const [selectedInvoiceItemsFiltered, setSelectedInvoiceItemsFiltered] = useState([]);
  const [loadingItems, setLoadingItems] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadModalState, setUploadModalState] = useState(uploadModalDefaultState);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD')
  });

  function mapType(type) {
    if (type === 1) {
      return 'Quick Books';
    }
    return 'Grocefy';
  }

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getData(page, query) {
    setLoading(true);
    console.log('business', business);
    const data = {
      StartDate: dayjs(dateRange.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      EndDate: dayjs(dateRange.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      Page: page,
      Size: currentSize,
      Query: query,
      BusinessId: business?.id,
      Status: selectedStatus
    };
    api
      .post(
        'inventory/invoices',
        data
      )
      .then((response) => {
        setLoading(false);
        setInvoicesData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function getItems() {
    setLoadingItems(true);
    api
      .get(`inventory/invoice/items/${selectedInvoice?.id}`)
      .then((response) => {
        setLoadingItems(false);
        setSelectedInvoiceItems(response.data.data);
        setSelectedInvoiceItemsFiltered(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoadingItems(false);
      });
  }

  function approve() {
    setLoadingItems(true);
    api
      .post('inventory/invoice/apply', {
        InvoiceId: selectedInvoiceData?.id,
        Approve: true,
        ApplyMovement: selectedInvoiceData?.applyMovement,
        ApplyPrice: selectedInvoiceData?.applyPrice,
        ApplyCost: selectedInvoiceData?.applyCost,
      })
      .then((response) => {
        setLoadingItems(false);
        showMessage(response.data.message);
        setSelectedInvoice(null);
      })
      .catch((error) => {
        showMessage(error.message);
        setLoadingItems(false);
      });
  }

  function reject() {
    setLoadingItems(true);
    api
      .post('inventory/invoice/apply', {
        InvoiceId: selectedInvoiceData?.id,
        Reject: true,
        ApplyMovement: selectedInvoiceData?.applyMovement,
        ApplyPrice: selectedInvoiceData?.applyPrice,
        ApplyCost: selectedInvoiceData?.applyCost,
      })
      .then((response) => {
        setLoadingItems(false);
        showMessage(response.data.message);
        setSelectedInvoice(null);
      })
      .catch((error) => {
        showMessage(error.message);
        setLoadingItems(false);
      });
  }

  function upload() {
    const formdata = new FormData();
    formdata.append('file', uploadModalState.fileData);
    formdata.append('type', uploadModalState.type);
    formdata.append('approve', uploadModalState.approve);
    formdata.append('applyMovement', uploadModalState.applyMovement);
    formdata.append('applyPrice', uploadModalState.applyPrice);
    formdata.append('applyCost', uploadModalState.applyCost);
    setUploading(true);
    api
      .post(`inventory/invoice/upload/${uploadModalState?.businessId}`, formdata)
      .then((response) => {
        setUploading(false);
        if (response.data.success) {
          getData(currentPage, searchTerm);
          setUploadModalState(uploadModalDefaultState);
        }
        showMessage(response.data.message);
      })
      .catch((error) => {
        showMessage(error.message);
        setUploading(false);
      });
  }

  function updateItem(item) {
    setLoadingItems(true);
    api
      .post('inventory/invoice/item/update', item)
      .then((response) => {
        setLoadingItems(false);
        showMessage(response.data.message);
      })
      .catch((error) => {
        showMessage(error.message);
        setLoadingItems(false);
      });
  }

  const draggerProps = {
    name: 'file',
    accept: '.xlsx',
    multiple: false,
    showUploadList: true,
    maxCount: 1,
    fileList: uploadModalState?.fileData ? [uploadModalState?.fileData] : [],
    beforeUpload: (file) => {
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        showMessage(t('wrong_file_type_selected'));
      } else {
        setUploadModalState({
          ...uploadModalState,
          fileData: file
        });
      }
      return false;
    },
    ondrop: (e) => {
      // console.log(e);
    },
    onRemove: (e) => {
      setUploadModalState({
        ...uploadModalState,
        fileData: null
      });
    }
  };

  useEffect(() => {
    setCurrentPage(0);
    getData(0, searchTerm);
  }, []);

  useEffect(() => {
    setSelectedInvoiceItems([]);
    if (selectedInvoice) {
      getItems();
    } else {
      setSelectedInvoiceData(null);
    }
  }, [selectedInvoice]);

  useEffect(() => {
    if (selectedInvoiceItemsFiltered) {
      setSelectedInvoiceItemsFiltered(_.filter(selectedInvoiceItems, (i) => `${i.itemCode} ${i.itemName}`?.toLowerCase().includes(selectedInvoiceData?.searchTerm?.toLowerCase())));
    }
  }, [selectedInvoiceData?.searchTerm]);

  useEffect(() => {
    setCurrentPage(0);
    getData(0, searchTerm);
  }, [dateRange, currentSize, selectedStatus]);

  return (
    <div>
      <PageHeader
        className="mb-4 px-0"
        title={t('invoices')}
        tags={
          <Tag
            color="#2db7f5"
            icon={<CheckCircleOutlined />}
          >
            {business ? business.name : t('global')}
          </Tag>
        }
        extra={
          [
            <RangePicker
              format="YYYY-MM-DD"
              value={[
                dayjs(dateRange?.start),
                dayjs(dateRange?.end)
              ]}
              disabled={loading}
              presets={dateRangePresets(t)}
              onChange={(date, dateString) => {
                setDateRange({
                  start: dateString[0],
                  end: dateString[1]
                });
              }}
            />,
            <div>
              <br className={width < 1024 ? null : 'hidden'} />
              <Select
                loading={loading}
                disabled={loading}
                style={{ width: 150 }}
                onChange={(value) => setSelectedStatus(value)}
                value={selectedStatus}
              >
                <Option key="all" value={0}>{t('all')}</Option>
                <Option key="pending" value={1}>{t('pending')}</Option>
                <Option key="approved" value={2}>{t('approved')}</Option>
                <Option key="rejected" value={3}>{t('rejected')}</Option>
              </Select>
            </div>,
            <div className="space-x-2">
              <br className={width < 1024 ? null : 'hidden'} />
              <Button
                type="primary"
                size="small"
                loading={loading}
                disabled={loading}
                icon={<PlusOutlined />}
                onClick={() => {
                  setUploadModalState({
                    ...uploadModalDefaultState,
                    open: true
                  });
                }}
              >
                {t('upload')}
              </Button>
            </div>,
            <div className="space-x-2">
              <br className={width < 1024 ? null : 'hidden'} />
              <Button
                type="primary"
                size="small"
                loading={loading}
                disabled={loading}
                icon={<ReloadOutlined />}
                onClick={() => {
                  setCurrentPage(0);
                  getData(0, searchTerm);
                }}
              >
                {t('refresh')}
              </Button>
            </div>
          ]
        }
      />
      <Input.Search
        className="w-full"
        key="invoices_search"
        allowClear
        loading={loading}
        enterButton={t('search')}
        onSearch={(value) => {
          setCurrentPage(0);
          setSearchTerm(value);
          getData(0, value);
        }}
      />
      <Table
        loading={loading}
        bordered
        className="mt-2"
        pagination={{
          pageSize: currentSize,
          showSizeChanger: true,
          defaultCurrent: 0,
          current: currentPage + 1,
          total: invoicesData?.total,
          onChange: (page, pageSize) => {
            if (page - 1 !== currentPage) {
              setCurrentPage(page - 1);
              getData(page - 1, searchTerm);
            } else {
              setCurrentSize(pageSize);
            }
          }
        }}
        dataSource={invoicesData?.invoices}
        columns={[
          {
            title: t('uploaded_on'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            className: 'text-xs',
            render: (text) => <span>{text ? renderDate(text, timezone) : '-'}</span>,
          },
          {
            title: t('status'),
            key: 'store',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <>
                {row.appliedOn && (
                  <Popover content={renderDate(row.appliedOn)}>
                    <Tag color="green">{t('approved')}</Tag>
                  </Popover>
                )}
                {row.rejectedOn && (
                  <Popover content={renderDate(row.rejectedOn)}>
                    <Tag color="red">{t('rejected')}</Tag>
                  </Popover>
                )}
                {!row.appliedOn && !row.rejectedOn && (
                  <Tag>{t('pending')}</Tag>
                )}
              </>
            ),
          },
          {
            title: t('file'),
            key: 'file',
            align: 'center',
            className: 'text-xs',
            render: (row) => <a className="text-blue-600 underline" href={row.fileUrl} target="_blank" rel="noreferrer">{row.fileName}</a>,
          },
          {
            title: t('store'),
            key: 'store',
            align: 'center',
            className: 'text-xs',
            render: (row) => <span>{row.businessName}</span>,
          },
          {
            title: t('actions'),
            key: 'actions',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setSelectedInvoice(row);
                  setSelectedInvoiceData({
                    ...row,
                    applyMovement: false,
                    applyPrice: false,
                    applyCost: false,
                  });
                }}
              >
                {t('view')}
              </Button>
            ),
          },
        ]}
      />
      <Modal
        width={width}
        title={t('invoice_items')}
        open={selectedInvoice}
        onCancel={() => setSelectedInvoice(null)}
        closable={!loadingItems}
        footer={[
          <Popconfirm
            title={t('are_you_sure_reject_invoice')}
            okText={t('yes')}
            cancelText={t('no')}
            onConfirm={() => reject()}
          >
            <Button
              key="reject"
              type="primary"
              danger
              disabled={selectedInvoice?.appliedOn || selectedInvoice?.rejectedOn || loadingItems}
              loading={loadingItems}
            >
              {t('reject')}
            </Button>
          </Popconfirm>,
          <Popconfirm
            title={t('are_you_sure_approve_invoice')}
            okText={t('yes')}
            cancelText={t('no')}
            onConfirm={() => approve()}
          >
            <Button
              key="approve"
              type="primary"
              loading={loadingItems}
              disabled={selectedInvoice?.appliedOn || selectedInvoice?.rejectedOn || loadingItems}
            >
              {t('approve')}
            </Button>
          </Popconfirm>,
          <Button
            key="close"
            type="primary"
            danger
            disabled={loadingItems}
            onClick={() => setSelectedInvoice(null)}
          >
            {t('close')}
          </Button>
        ]}
      >
        <Input
          value={selectedInvoiceData?.searchTerm}
          onChange={(obj) => {
            setSelectedInvoiceData({
              ...selectedInvoiceData,
              searchTerm: obj.target.value
            });
          }}
        />
        <Table
          loading={loadingItems}
          bordered
          className="mt-2"
          dataSource={selectedInvoiceItemsFiltered}
          columns={[
            {
              title: t('code'),
              key: 'code',
              align: 'center',
              className: 'text-xs',
              render: (row) => <span>{row.itemCode}</span>,
            },
            {
              title: t('name'),
              key: 'name',
              align: 'center',
              className: 'text-xs',
              render: (row) => <span>{row.itemName}</span>,
            },
            {
              title: t('amount'),
              key: 'amount',
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <InputNumber
                  disabled={selectedInvoice?.appliedOn || selectedInvoice?.rejectedOn || loadingItems}
                  className="w-full"
                  min={0}
                  step="0.01"
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(selectedInvoiceItems));
                    for (let i = 0; i < temp.length; i++) {
                      if (temp[i].id === row.id) {
                        temp[i].finalAmount = obj;
                      }
                    }
                    setSelectedInvoiceItems(temp);
                    const temp2 = JSON.parse(JSON.stringify(selectedInvoiceItemsFiltered));
                    for (let i = 0; i < temp2.length; i++) {
                      if (temp2[i].id === row.id) {
                        temp2[i].finalAmount = obj;
                      }
                    }
                    setSelectedInvoiceItemsFiltered(temp2);
                  }}
                  value={row?.finalAmount}
                />
              ),
            },
            {
              title: t('price'),
              key: 'price',
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <InputNumber
                  disabled={selectedInvoice?.appliedOn || selectedInvoice?.rejectedOn || loadingItems}
                  className="w-full"
                  min={0}
                  step="0.01"
                  addonBefore="$"
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(selectedInvoiceItems));
                    for (let i = 0; i < temp.length; i++) {
                      if (temp[i].id === row.id) {
                        temp[i].price = obj;
                      }
                    }
                    setSelectedInvoiceItems(temp);
                    const temp2 = JSON.parse(JSON.stringify(selectedInvoiceItemsFiltered));
                    for (let i = 0; i < temp2.length; i++) {
                      if (temp2[i].id === row.id) {
                        temp2[i].price = obj;
                      }
                    }
                    setSelectedInvoiceItemsFiltered(temp2);
                  }}
                  value={row?.price}
                />
              ),
            },
            {
              title: t('cost'),
              key: 'cost',
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <InputNumber
                  disabled={selectedInvoice?.appliedOn || selectedInvoice?.rejectedOn || loadingItems}
                  className="w-full"
                  min={0}
                  step="0.01"
                  addonBefore="$"
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={(obj) => {
                    const temp = JSON.parse(JSON.stringify(selectedInvoiceItems));
                    for (let i = 0; i < temp.length; i++) {
                      if (temp[i].id === row.id) {
                        temp[i].cost = obj;
                      }
                    }
                    setSelectedInvoiceItems(temp);
                    const temp2 = JSON.parse(JSON.stringify(selectedInvoiceItemsFiltered));
                    for (let i = 0; i < temp2.length; i++) {
                      if (temp2[i].id === row.id) {
                        temp2[i].cost = obj;
                      }
                    }
                    setSelectedInvoiceItemsFiltered(temp2);
                  }}
                  value={row?.cost}
                />
              ),
            },
            {
              title: t('actions'),
              key: 'actions',
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <Button
                  size="small"
                  type="primary"
                  disabled={selectedInvoice?.appliedOn || selectedInvoice?.rejectedOn || loadingItems}
                  onClick={() => updateItem(row)}
                >
                  {t('update')}
                </Button>
              ),
            },
          ]}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-2">
          <div className="space-x-2 flex justify-center">
            <strong>{t('apply_movement')}</strong>
            <Switch
              disabled={selectedInvoice?.appliedOn || selectedInvoice?.rejectedOn || loadingItems}
              checked={selectedInvoiceData?.applyMovement}
              onChange={(checked) => {
                setSelectedInvoiceData({
                  ...selectedInvoiceData,
                  applyMovement: checked
                });
              }}
            />
          </div>
          <div className="space-x-2 flex justify-center">
            <strong>{t('apply_cost')}</strong>
            <Switch
              disabled={selectedInvoice?.appliedOn || selectedInvoice?.rejectedOn || loadingItems}
              checked={selectedInvoiceData?.applyCost}
              onChange={(checked) => {
                setSelectedInvoiceData({
                  ...selectedInvoiceData,
                  applyCost: checked
                });
              }}
            />
          </div>
          <div className="space-x-2 flex justify-center md:col-span-2 lg:col-span-1">
            <strong>{t('apply_price')}</strong>
            <Switch
              disabled={selectedInvoice?.appliedOn || selectedInvoice?.rejectedOn || loadingItems}
              checked={selectedInvoiceData?.applyPrice}
              onChange={(checked) => {
                setSelectedInvoiceData({
                  ...selectedInvoiceData,
                  applyPrice: checked
                });
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        width={550}
        title={t('upload_new_invoice')}
        open={uploadModalState?.open}
        onCancel={() => setUploadModalState(uploadModalDefaultState)}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            disabled={uploading}
            onClick={() => setUploadModalState(uploadModalDefaultState)}
          >
            {t('close')}
          </Button>,
          <Button
            key="upload"
            type="primary"
            disabled={uploading || uploadModalState?.fileData === null || uploadModalState?.businessId === ''}
            loading={uploading}
            onClick={() => upload()}
          >
            {t('upload')}
          </Button>
        ]}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-2">
          <Dropdown
            className="w-full"
            trigger="click"
            disabled={uploading}
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    setUploadModalState({
                      ...uploadModalState,
                      businessId: ''
                    });
                  }}
                >
                  {t('not_selected')}
                </Menu.Item>
                {businesses?.state === 'hasValue' && _.map(businesses?.contents?.data?.data?.results, (b) => (
                  <Menu.Item
                    onClick={() => {
                      setUploadModalState({
                        ...uploadModalState,
                        businessId: b.id,
                        businessName: b.name
                      });
                    }}
                  >
                    {b.name}
                  </Menu.Item>
                ))}
              </Menu>
            }
            arrow="true"
          >
            <Button loading={businesses?.state !== 'hasValue'}>
              {t('store')}: {uploadModalState?.businessId !== '' ? uploadModalState?.businessName : t('not_selected')}
              <DownSquareOutlined />
            </Button>
          </Dropdown>
          <Dropdown
            className="w-full"
            trigger="click"
            disabled={uploading}
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    setUploadModalState({
                      ...uploadModalState,
                      type: 0
                    });
                  }}
                >
                  Grocefy
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    setUploadModalState({
                      ...uploadModalState,
                      type: 1
                    });
                  }}
                >
                  Quick Books
                </Menu.Item>
              </Menu>
            }
            arrow="true"
          >
            <Button loading={businesses?.state !== 'hasValue'}>
              {t('type')}: {mapType(uploadModalState?.type)}
              <DownSquareOutlined />
            </Button>
          </Dropdown>
          <div className="space-x-2 flex justify-center">
            <strong>{t('approve')}</strong>
            <Switch
              disabled={uploading}
              checked={uploadModalState?.approve}
              onChange={(checked) => {
                setUploadModalState({
                  ...uploadModalState,
                  approve: checked
                });
              }}
            />
          </div>
          {uploadModalState?.approve && (
            <>
              <div className="space-x-2 flex justify-center">
                <strong>{t('apply_movement')}</strong>
                <Switch
                  disabled={uploading}
                  checked={uploadModalState?.applyMovement}
                  onChange={(checked) => {
                    setUploadModalState({
                      ...uploadModalState,
                      applyMovement: checked
                    });
                  }}
                />
              </div>
              <div className="space-x-2 flex justify-center">
                <strong>{t('apply_cost')}</strong>
                <Switch
                  disabled={uploading}
                  checked={uploadModalState?.applyCost}
                  onChange={(checked) => {
                    setUploadModalState({
                      ...uploadModalState,
                      applyCost: checked
                    });
                  }}
                />
              </div>
              <div className="space-x-2 flex justify-center">
                <strong>{t('apply_price')}</strong>
                <Switch
                  disabled={uploading}
                  checked={uploadModalState?.applyPrice}
                  onChange={(checked) => {
                    setUploadModalState({
                      ...uploadModalState,
                      applyPrice: checked
                    });
                  }}
                />
              </div>
            </>
          )}
        </div>
        <Dragger disabled={uploading} {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p
            className="ant-upload-text"
            style={{ margin: 10 }}
          >
            {t('click_or_drag_content')}
          </p>
        </Dragger>
      </Modal>
    </div>
  );
}

export default Invoices;
