import React, { useState, useEffect } from 'react';
import { useMount } from 'react-use';
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';
import { useJsonToCsv } from 'react-json-csv';
import {
  Button,
  Table,
  Card,
  DatePicker,
} from 'antd';
import dayjs from 'dayjs';
import { PageHeader } from '@ant-design/pro-layout';
import {
  CloudDownloadOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { timezoneAtom, profileAtom, selectedOrderAtom, viewDetailsAtom } from '../../../../atoms/Atoms';
import { thousandv2, toCurrency, renderShortDateTime, mapOrderType, mapOrderStatus, mapPaymentType, renderShortDateHour, renderDate, formatPhoneNumber } from '../../../utils/functions';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';
import { dateRangePresets } from '../../../utils/utils';

function GiftCardsPurchaseHistory() {
  const { RangePicker } = DatePicker;
  const { t } = useTranslation();
  const { saveAsCsv } = useJsonToCsv();
  const timezone = useRecoilValue(timezoneAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const setSelectedOrder = useSetRecoilState(selectedOrderAtom);
  const setViewDetails = useSetRecoilState(viewDetailsAtom);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentSize, setCurrentSize] = useState(20);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);

  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const isStoreManager =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('StoreAdmin') ||
        profile?.contents?.roles?.includes('Owner')));
  const canViewFinance =
    isAdmin ||
    isStoreManager ||
    (profile.contents &&
      (profile.contents.roles.includes('Finance') ||
        profile.contents.roles.includes('TopAnalytics')));
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD')
  });

  const width = window.innerWidth;

  function getData(page) {
    setLoading(true);
    const data = {
      StartDate: dayjs(dateRange.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      EndDate: dayjs(dateRange.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      Page: page,
      Size: currentSize,
      ExportAll: false
    };
    api
      .post(
        'giftcards/purchase/report',
        data
      )
      .then((response) => {
        setLoading(false);
        setReportData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function exportData() {
    setLoadingExport(true);
    const data = {
      StartDate: dayjs(dateRange.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      EndDate: dayjs(dateRange.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      Page: 0,
      Size: currentSize,
      ExportAll: true
    };
    api
      .post(
        'giftcards/purchase/report',
        data
      )
      .then((response) => {
        setLoadingExport(false);
        saveAsCsv({
          data: response.data.data.transactions,
          fields: {
            date: t('date'),
            code: t('code'),
            amount: t('amount'),
            giftCardCurrentBalance: t('current_balance'),
            confirmationCode: t('confirmation_code'),
            storeName: t('store_name'),
          },
          filename: `Gift Card Purchases ${moment(dateRange.start).format('MM/DD/YYYY')} - ${moment(dateRange.end).format('MM/DD/YYYY')}`,
        });
      })
      .catch((error) => {
        console.error(error);
        setLoadingExport(false);
      });
  }

  function loadOrder(orderId) {
    setLoadingOrder(true);
    let tempReportData = JSON.parse(JSON.stringify(reportData));
    for (let i = 0; i < tempReportData.transactions.length; i++) {
      const transaction = tempReportData.transactions[i];
      if (transaction.orderId === orderId) {
        transaction.loading = true;
      }
    }
    setReportData(tempReportData);
    api
      .get(`orders/order/${orderId}`)
      .then((response) => {
        setLoadingOrder(false);
        const order = response.data.data;
        tempReportData = JSON.parse(JSON.stringify(reportData));
        for (let i = 0; i < tempReportData.transactions.length; i++) {
          const transaction = tempReportData.transactions[i];
          transaction.loading = false;
        }
        setReportData(tempReportData);
        setViewDetails(true);
        setSelectedOrder({
          ...order,
          key: order.id,
          id: order.id,
          itemCount: order.itemCount,
          storeId: order.businessId,
          locationId: order.locationId,
          orderType: order.type,
          deliveryZone: order.deliveryZoneName,
          createdAt: renderShortDateTime(order.actualTime, timezone),
          cancelledAt: renderShortDateTime(order.cancellationDate, timezone),
          createdDiff: moment(`${order.createdAt}+0000`).tz(timezone).fromNow(),
          dispatchDiff:
            order.dispatchDateTime == null
              ? 'N/A'
              : moment(`${order.dispatchDateTime}+0000`).tz(timezone).fromNow(),
          businessName: order.businessName,
          type: t(mapOrderType(order.type)),
          typeEnum: order.type,
          confirmationCode: order.confirmationCode,
          customer: order.customerName,
          customerId: order.customerId,
          customerPhone: formatPhoneNumber(order.customerPhone),
          customerLocationId: order.customerLocationId,
          ivuCN: order.ivuControlNumber === null ? 'N/A' : order.ivuControlNumber,
          statusText: mapOrderStatus(order.status),
          cardNickname: order.cardNickname,
          nickName: order.nickName,
          cardholderName: order.cardholderName,
          status: order.status,
          paymentTypeText: t(mapPaymentType(order.paymentType)),
          paymentType: order.paymentType,
          desiredDateTime: renderShortDateHour(order.desiredDateTime, timezone),
          date: renderShortDateHour(order.date, timezone),
          dDateTime: order.desiredDateTime,
          dispatchDateTime: renderDate(order.dispatchDateTime, timezone),
          total: order.orderTotal,
          orderTotal: order.orderTotal,
          subTotal: order.productsTotal,
          munTax: order.municipalTax,
          stateTax: order.stateTax,
          deliveryFee: order.deliveryTotal,
          pickupFee: order.processingFee,
          transferTotal: order.transferTotal,
          processingFee: order.processingFee,
          transferCompletedText: order.transferCompleted ? 'Yes' : 'No',
          transferCompleted: order.transferCompleted,
          authTotal: order.authTotal,
          returnTotal: order.returnTotal,
          credit:
            order.authTotal - order.orderTotal
              ? (order.authTotal - order.orderTotal)
              : '0.00',
          referenceNumber:
            order.referenceNumber || order.referenceNumber !== ''
              ? order.referenceNumber
              : 'n/a',
          refNumAvailable: order.referenceNumber !== '' && order.referenceNumber !== null,
          discountAmount: order.discountAmount,
          businessDescription: order.businessDescription,
          returnNeeded: order.returnNeeded ? 'Yes' : 'No',
          returnCompleted: order.returnCompleted ? 'Yes' : 'No',
          athReturnError: order.athReturnError ? 'Yes' : 'No',
          wasAlreadyCharged: order.hasBeenCharged ? 'Yes' : 'No',
          wasAlreadyAuthed: order.hasBeenAuthed ? 'Yes' : 'No',
          hasBeenCharged: order.hasBeenCharged,
          hasBeenAuthed: order.hasBeenAuthed,
          items: order.items,
          signatureImageUrl: order.signatureImageUrl,
          ebtReceiptUrl: order.ebtReceiptUrl,
          pickUpName: order.pickUpName,
          customerLocation: order.customerLocation,
          paymentTender: order.paymentTender,
          posLaneTag: order.posLaneTag,
        });
      })
      .catch((error) => {
        console.error(error);
        setLoadingOrder(false);
      });
  }

  const allColumns = [{
    title: t('day'),
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{renderShortDateTime(text, timezone)}</span>,
  },
  {
    title: t('code'),
    dataIndex: 'code',
    key: 'code',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{text}</span>,
  },
  {
    title: t('amount'),
    dataIndex: 'amount',
    key: 'amount',
    align: 'right',
    className: 'text-xs',
    render: (text) => <span>{toCurrency(text)}</span>,
  },
  {
    title: t('current_balance'),
    dataIndex: 'giftCardCurrentBalance',
    key: 'giftCardCurrentBalance',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{toCurrency(text)}</span>,
  },
  {
    title: t('order'),
    key: 'confirmationCode',
    align: 'center',
    className: 'text-xs',
    render: (text) => (
      <Button
        size="small"
        type="primary"
        loading={text.loading}
        disabled={loadingOrder}
        onClick={() => loadOrder(text.confirmationCode)}
      >
        {text.confirmationCode}
      </Button>
    ),
  },
  {
    title: t('business'),
    dataIndex: 'storeName',
    key: 'storeName',
    align: 'right',
    className: 'text-xs',
    render: (text) => <span>{text}</span>,
  }];

  useMount(() => {
    setCurrentPage(0);
    getData(0);
  });

  useEffect(() => {
    setCurrentPage(0);
    getData(0);
  }, [dateRange, currentSize]);

  return (
    <div>
      <PageHeader
        className="mb-4 px-0"
        title={t('purchase_history')}
        extra={
          [
            <RangePicker
              format="YYYY-MM-DD"
              value={[
                dayjs(dateRange?.start),
                dayjs(dateRange?.end)
              ]}
              disabled={loading || loadingExport}
              presets={dateRangePresets(t)}
              onChange={(date, dateString) => {
                setDateRange({
                  start: dateString[0],
                  end: dateString[1]
                });
              }}
            />,
            <div className="space-x-2">
              <br className={width < 1024 ? null : 'hidden'} />
              <Button
                type="primary"
                size="small"
                loading={loading}
                disabled={loading || loadingExport}
                icon={<ReloadOutlined />}
                onClick={() => {
                  setCurrentPage(0);
                  getData(0);
                }}
              >
                {t('refresh')}
              </Button>
            </div>,
            <Button
              type="primary"
              size="small"
              loading={loadingExport}
              disabled={loading || loadingExport}
              icon={<CloudDownloadOutlined />}
              onClick={() => {
                exportData();
              }}
            >
              {t('export')}
            </Button>
          ]
        }
      />
      <Table
        loading={loading}
        bordered
        pagination={{
          pageSize: currentSize,
          showSizeChanger: true,
          defaultCurrent: 0,
          current: currentPage + 1,
          total: reportData?.total,
          onChange: (page, pageSize) => {
            if (page - 1 !== currentPage) {
              setCurrentPage(page - 1);
              getData(page - 1);
            } else {
              setCurrentSize(pageSize);
            }
          }
        }}
        columns={allColumns}
        dataSource={reportData?.transactions}
      />
    </div>
  );
}

export default GiftCardsPurchaseHistory;
