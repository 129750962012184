import React, { useEffect, useState } from 'react';
import {
  useRecoilState,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Switch,
  Input,
  InputNumber,
  Upload,
  Button,
  notification,
  Avatar,
  Image,
  Tooltip
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  InboxOutlined
} from '@ant-design/icons';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  selectedAddonAtom,
  selectedOptionAtom,
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import { makeid } from '../../../utils/functions';

function EditAddonOption() {
  const { Dragger } = Upload;
  const [selectedOption, setSelectedOption] = useRecoilState(selectedOptionAtom);
  const [selectedAddon, setSelectedAddon] = useRecoilState(selectedAddonAtom);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState(null);
  const { t } = useTranslation();

  const draggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageData(file);
      return false;
    }
  };

  const removeImage = function () {
    const temp = JSON.parse(JSON.stringify(data));
    temp.imageUrl = '';
    setData(temp);
  };

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const submitClick = function () {
    const tempSelectedReq = JSON.parse(JSON.stringify(selectedAddon));
    if (data.id) {
      for (let i = 0; i < tempSelectedReq.items.length; i++) {
        if (tempSelectedReq.items[i].id === data.id) {
          tempSelectedReq.items[i].name = data.name;
          tempSelectedReq.items[i].description = data.description;
          tempSelectedReq.items[i].isAvailable = data.isAvailable;
          tempSelectedReq.items[i].ordering = data.ordering;
          tempSelectedReq.items[i].imageUrl = data.imageUrl;
          tempSelectedReq.items[i].isAlcoholicBeverage = data.isAlcoholicBeverage;
          tempSelectedReq.items[i].price = data.price;
          tempSelectedReq.items[i].allowVariableAmount = data.allowVariableAmount;
        }
      }
    } else {
      if (!tempSelectedReq.items) {
        tempSelectedReq.items = [];
      }
      data.id = makeid();
      tempSelectedReq.items.push(data);
    }
    setSelectedAddon(tempSelectedReq);
    setSelectedOption(null);
  };

  useEffect(() => {
    if (imageData) {
      const file = imageData;
      const boundary = Date.now();
      const tempdata = new FormData();
      tempdata.append('file', file);
      setLoading(true);
      api
        .post('businesses/addonoptionsimages/upload/file', tempdata)
        .then((response) => {
          setLoading(false);
          setImageData(null);
          if (response.data.success) {
            const temp = JSON.parse(JSON.stringify(data));
            temp.imageUrl = response.data.data;
            setData(temp);
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          setImageData(null);
          console.error(error);
        });
    }
  }, [imageData]);

  useEffect(() => {
    if (selectedOption) {
      const temp = JSON.parse(JSON.stringify(selectedOption));
      if (!temp.id) {
        temp.ordering = 99;
        temp.isAvailable = true;
        temp.isAlcoholicBeverage = false;
        temp.price = 1.00;
      }
      temp.price = temp.price.toFixed(2);
      setData(temp);
    }
  }, [selectedOption]);

  return (
    <Drawer
      title={`${selectedOption && selectedOption.id ? 'Update' : 'Create'} Option`}
      placement="right"
      closable
      onClose={() => setSelectedOption(null)}
      open={selectedOption}
      width={500}
    >
      <>
        {data && (
          <>
            {data.id && (
              <div style={{ margin: '5px 0' }}>
                <strong>{t('available')}?:</strong>
                <Switch
                  disabled={loading}
                  checked={data.isAvailable}
                  style={{ float: 'right' }}
                  onChange={(checked) => {
                    const temp = JSON.parse(JSON.stringify(data));
                    temp.isAvailable = checked;
                    setData(temp);
                  }}
                />
              </div>
            )}
            <div className="mb-2">
              <strong>{t('allow_variable_amount')}:</strong>
              <Switch
                disabled={loading}
                checked={data.allowVariableAmount}
                style={{ float: 'right' }}
                onChange={(checked) => {
                  setData({
                    ...data,
                    allowVariableAmount: checked
                  });
                }}
              />
            </div>
            <div style={{ margin: '5px 0' }}>
              <strong>{t('alcoholic_beverage')}?:</strong>
              <Switch
                disabled={loading}
                checked={data.isAlcoholicBeverage}
                style={{ float: 'right' }}
                onChange={(checked) => {
                  const temp = JSON.parse(JSON.stringify(data));
                  temp.isAlcoholicBeverage = checked;
                  setData(temp);
                }}
              />
            </div>
            <div style={{ margin: '5px 0' }}>
              <strong>{t('name')}:</strong>
              <Input
                disabled={loading}
                value={data.name}
                placeholder="Name"
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(data));
                  temp.name = obj.target.value;
                  setData(temp);
                }}
              />
            </div>
            <div style={{ margin: '5px 0' }}>
              <strong>{t('description')}:</strong>
              <Input
                disabled={loading}
                value={data.description}
                placeholder="Description"
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(data));
                  temp.description = obj.target.value;
                  setData(temp);
                }}
              />
            </div>
            <div style={{ margin: '5px 0' }}>
              <strong>{t('price')}: </strong>
              <InputNumber
                disabled={loading}
                value={data.price}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(data));
                  temp.price = obj;
                  setData(temp);
                }}
              />
            </div>
            <div style={{ margin: '5px 0' }}>
              <Tooltip title={t('image_requirements')}>
                <strong>{t('image')}:</strong>
              </Tooltip>
              <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  {t('click_or_drag_content')}
                </p>
              </Dragger>
              {data.imageUrl && (
                <>
                  <Image
                    alt={data.imageUrl}
                    src={data.imageUrl}
                    height={75}
                  />
                  <Button loading={loading} onClick={() => removeImage()}>
                    {t('delete')}
                  </Button>
                </>
              )}
            </div>
            <Button
              type="primary"
              loading={loading}
              style={{
                margin: '10px 0',
                float: 'right'
              }}
              onClick={() => submitClick()}
            >
              {data && data.id ? 'Update' : 'Create'}
            </Button>
          </>
        )}
      </>
    </Drawer>
  );
}

export default EditAddonOption;
