import React, { useState, useEffect } from 'react';
import { useMount } from 'react-use';
import { useSetRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';
import { useJsonToCsv } from 'react-json-csv';
import {
  Button,
  Table,
  Select,
  Card,
  DatePicker,
  Input,
  Modal,
  InputNumber,
  Popconfirm,
  notification,
  Avatar,
  Tooltip
} from 'antd';
import dayjs from 'dayjs';
import { PageHeader } from '@ant-design/pro-layout';
import { Comment } from '@ant-design/compatible';
import {
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { timezoneAtom, profileAtom, giftCardHistoryAtom } from '../../../atoms/Atoms';
import { thousandv2, toCurrency, renderShortDateTime } from '../../utils/functions';
import api from '../../../api/api';
import envConfig from '../../../envConfig';
import { dateRangePresets } from '../../utils/utils';
import GiftCardHistoryModal from '../reports/shared-components/GiftCardsHistoryModal';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';

function GiftCards() {
  const bottomRowGridStyle = { width: '100%', textAlign: 'center' };
  const { RangePicker } = DatePicker;
  const { t } = useTranslation();
  const { Option } = Select;
  const { saveAsCsv } = useJsonToCsv();
  const timezone = useRecoilValue(timezoneAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const setSelectedGiftCard = useSetRecoilState(giftCardHistoryAtom);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentSize, setCurrentSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');
  const [creatingGiftCards, setCreatingGiftCards] = useState(false);
  const [createGiftCards, setCreateGiftCards] = useState(false);
  const [totalGiftCards, setTotalGiftCards] = useState(1);

  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const isStoreManager =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('StoreAdmin') ||
        profile?.contents?.roles?.includes('Owner')));
  const canViewFinance =
    isAdmin ||
    isStoreManager ||
    (profile.contents &&
      (profile.contents.roles.includes('Finance') ||
        profile.contents.roles.includes('TopAnalytics')));
  const [reportData, setReportData] = useState(null);
  const [accountPayableData, setAccountPayableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingAccountPayable, setLoadingAccountPayable] = useState(false);
  const [selectedCardType, setSelectedCardType] = useState(2);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD')
  });

  const width = window.innerWidth;
  const allColumns = [{
    title: t('day'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{renderShortDateTime(text, timezone)}</span>,
  },
  {
    title: t('activated_on'),
    dataIndex: 'activatedOn',
    key: 'activatedOn',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{text ? renderShortDateTime(text, timezone) : '-'}</span>,
  },

  {
    title: t('code'),
    dataIndex: 'code',
    key: 'code',
    align: 'right',
    className: 'text-xs',
    render: (text) => <span>{text}</span>,
  },
  {
    title: t('opening_balance'),
    dataIndex: 'openingBalance',
    key: 'openingBalance',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{toCurrency(text)}</span>,
  },
  {
    title: t('lifetime_balance'),
    dataIndex: 'balance',
    key: 'balance',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{toCurrency(text)}</span>,
  },
  {
    title: t('current_balance'),
    dataIndex: 'currentBalanceLeft',
    key: 'currentBalanceLeft',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{toCurrency(text)}</span>,
  },
  {
    title: '',
    key: 'viewhistory',
    align: 'center',
    className: 'text-xs',
    render: (row) =>
      <Button
        size="small"
        type="primary"
        onClick={() => setSelectedGiftCard(row)}
      >
        {t('history')}
      </Button>,
  }];

  function isPositiveNumber(str) {
    if (typeof str !== 'string') return false;
    const n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n > 0;
  }

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getData(page, query) {
    setLoading(true);
    const data = {
      StartDate: dayjs(dateRange.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      EndDate: dayjs(dateRange.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      Page: page,
      Size: currentSize,
      Query: query,
      SearchType: selectedCardType
    };
    api
      .post(
        'giftcards/search',
        data
      )
      .then((response) => {
        setLoading(false);
        setReportData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function getAccountPayable() {
    setLoadingAccountPayable(true);
    api
      .get('giftcards/accountpayable')
      .then((response) => {
        setLoadingAccountPayable(false);
        setAccountPayableData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoadingAccountPayable(false);
      });
  }

  function generateGiftCards() {
    setCreatingGiftCards(true);
    api
      .post('giftcards/create', {
        Total: totalGiftCards
      }, { responseType: 'blob' })
      .then((response) => {
        setCreatingGiftCards(false);
        setCreateGiftCards(false);
        showMessage(t('created_codes_successfully'));
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `gift_cards_${new Date().toLocaleDateString()}.txt`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        showMessage(error.message);
        setCreatingGiftCards(false);
      });
  }

  useMount(() => {
    setCurrentPage(0);
    getData(0, searchTerm);
    getAccountPayable();
    setTotalGiftCards(1);
  });

  useEffect(() => {
    setCurrentPage(0);
    getData(0, searchTerm);
  }, [dateRange, currentSize]);

  useEffect(() => {
    setTotalGiftCards(1);
  }, [createGiftCards]);

  return (
    <div>
      <PageHeader
        className="mb-4 px-0"
        title={t('search')}
        extra={
          [
            <RangePicker
              format="YYYY-MM-DD"
              value={[
                dayjs(dateRange?.start),
                dayjs(dateRange?.end)
              ]}
              disabled={loading}
              presets={dateRangePresets(t)}
              onChange={(date, dateString) => {
                setDateRange({
                  start: dateString[0],
                  end: dateString[1]
                });
              }}
            />,
            <div>
              <br className={width < 1024 ? null : 'hidden'} />
              <Select
                loading={loading}
                disabled={loading}
                style={{ width: 150 }}
                onChange={(value) => setSelectedCardType(value)}
                value={selectedCardType}
              >
                <Option key="all" value={0}>{t('all')}</Option>
                <Option key="activated" value={1}>{t('activated')}</Option>
                <Option key="with_balance" value={2}>{t('with_balance')}</Option>
                <Option key="no_balance" value={3}>{t('no_balance')}</Option>
                <Option key="not_activated" value={4}>{t('not_activated')}</Option>
              </Select>
            </div>,
            <div className="space-x-2">
              <br className={width < 1024 ? null : 'hidden'} />
              <Button
                type="primary"
                size="small"
                loading={loading}
                disabled={loading}
                icon={<ReloadOutlined />}
                onClick={() => {
                  setCurrentPage(0);
                  getData(0, searchTerm);
                  getAccountPayable();
                }}
              >
                {t('refresh')}
              </Button>
            </div>,
            <div className="space-x-2">
              <br className={width < 1024 ? null : 'hidden'} />
              <Button
                type="primary"
                size="small"
                icon={<PlusOutlined />}
                onClick={() => setCreateGiftCards(true)}
              >
                {t('create')}
              </Button>
            </div>
          ]
        }
      />
      <Input.Search
        className="w-full"
        key="gift_card_search"
        allowClear
        loading={loading}
        enterButton={t('search')}
        onSearch={(value) => {
          setCurrentPage(0);
          setSearchTerm(value);
          getData(0, value);
        }}
      />
      <div className="divide-y space-y-4 mb-4">
        <dl className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
          <Card
            type="inner"
            title={t('total_active_cards')}
            className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
            hoverable
            loading={loadingAccountPayable}
          >
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span> {thousandv2(accountPayableData?.totalActiveGiftCards, true)}</span>
            </Card.Grid>
          </Card>
          <Card
            type="inner"
            title={t('account_payable')}
            className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
            hoverable
            loading={loadingAccountPayable}
          >
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span> {toCurrency(accountPayableData?.accountPayable)}</span>
            </Card.Grid>
          </Card>
        </dl>
      </div>
      <Table
        loading={loading}
        bordered
        pagination={{
          pageSize: currentSize,
          showSizeChanger: true,
          defaultCurrent: 0,
          current: currentPage + 1,
          total: reportData?.total,
          onChange: (page, pageSize) => {
            if (page - 1 !== currentPage) {
              setCurrentPage(page - 1);
              getData(page - 1, searchTerm);
            } else {
              setCurrentSize(pageSize);
            }
          }
        }}
        columns={allColumns}
        dataSource={reportData?.giftCards}
      />
      <GiftCardHistoryModal />
      <Modal
        title={t('create_gift_cards')}
        open={createGiftCards}
        closable={!creatingGiftCards}
        onCancel={() => {
          setCreateGiftCards(false);
        }}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            disabled={creatingGiftCards}
            onClick={() => {
              setCreateGiftCards(false);
            }}
          >
            {t('close')}
          </Button>,
          <Popconfirm
            title={t('create_gift_cards_prompt')}
            okText={t('yes')}
            cancelText={t('no')}
            disabled={isPositiveNumber(totalGiftCards)}
            onConfirm={() => generateGiftCards()}
          >
            <Button
              key="create"
              type="primary"
              disabled={isPositiveNumber(totalGiftCards)}
              loading={creatingGiftCards}
            >
              {t('create')}
            </Button>
          </Popconfirm>
        ]}
      >
        <div
          className="mb-4"
        >
          <strong>{t('amount_to_create')}</strong>
          <InputNumber
            disabled={creatingGiftCards}
            className="w-full"
            min={1}
            step="1"
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(obj) => setTotalGiftCards(obj)}
            value={totalGiftCards}
          />
        </div>
      </Modal>
    </div>
  );
}

export default GiftCards;
