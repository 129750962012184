import React, { useEffect, useState } from 'react';
import {
  useRecoilState,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Input,
  Drawer,
  Button,
  notification,
  Avatar,
  Image,
  Tooltip
} from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  selectedSideAtom,
  selectedPickingItemAtom,
  selectedOptionAtom,
} from '../../../../atoms/Atoms';
import Search from '../../searchoptimization/shared-components/Search';
import { makeid } from '../../../utils/functions';

function EditSideOption() {
  const [selectedPickingItem, setSelectedPickingItem] =
    useRecoilState(selectedPickingItemAtom);
  const [selectedOption, setSelectedOption] = useRecoilState(selectedOptionAtom);
  const [selectedSide, setSelectedSide] = useRecoilState(selectedSideAtom);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const submitClick = function () {
    const tempSelectedReq = JSON.parse(JSON.stringify(selectedSide));
    if (data.id) {
      for (let i = 0; i < tempSelectedReq.items.length; i++) {
        if (tempSelectedReq.items[i].id === data.id) {
          tempSelectedReq.items[i].name = data.name;
          tempSelectedReq.items[i].description = data.description;
          tempSelectedReq.items[i].brand = data.brand;
          tempSelectedReq.items[i].ordering = data.ordering;
          tempSelectedReq.items[i].imageUrl = data.imageUrl;
          tempSelectedReq.items[i].itemId = data.itemId;
        }
      }
    } else {
      if (!tempSelectedReq.items) {
        tempSelectedReq.items = [];
      }
      data.id = makeid();
      tempSelectedReq.items.push(data);
    }
    setSelectedSide(tempSelectedReq);
    setSelectedOption(null);
  };

  useEffect(() => {
    if (selectedOption) {
      setLoading(false);
      setSelectedPickingItem(null);
      const temp = JSON.parse(JSON.stringify(selectedOption));
      if (!temp.id) {
        temp.ordering = 99;
      }
      setData(temp);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedPickingItem && data) {
      const temp = JSON.parse(JSON.stringify(data));
      temp.brand = selectedPickingItem?.brand;
      temp.name = selectedPickingItem?.name;
      temp.description = selectedPickingItem?.description;
      temp.imageUrl = selectedPickingItem?.itemImage;
      temp.itemId = selectedPickingItem?.id;
      setData(temp);
    }
  }, [selectedPickingItem]);

  return (
    <Drawer
      title={`${selectedOption && selectedOption.id ? t('update') : t('create')} Option`}
      placement="right"
      closable
      onClose={() => setSelectedOption(null)}
      open={selectedOption}
      width={500}
    >
      <>
        {data && (
          <>
            <div style={{ margin: '5px 0' }}>
              <strong>{t('brand')}:</strong>
              <Input
                disabled
                value={data.brand}
                placeholder={t('brand')}
              />
            </div>
            <div style={{ margin: '5px 0' }}>
              <strong>{t('name')}:</strong>
              <Input
                disabled
                value={data.name}
                placeholder={t('name')}
              />
            </div>
            <div style={{ margin: '5px 0' }}>
              <strong>{t('description')}:</strong>
              <Input
                disabled
                value={data.description}
                placeholder={t('description')}
              />
            </div>
            <div style={{ margin: '5px 0' }}>
              <Tooltip title={t('image_requirements')}>
                <strong>{t('image')}:</strong>
              </Tooltip>
              {data.imageUrl && (
                <Image
                  alt={data.imageUrl}
                  src={data.imageUrl}
                  height={75}
                />
              )}
            </div>
            <Button
              type="primary"
              loading={loading}
              disabled={!data.itemId && !data.menuItemId}
              style={{
                margin: '10px 0',
                float: 'right'
              }}
              onClick={() => submitClick()}
            >
              {data && data.id ? t('update') : t('create')}
            </Button>
            <br />
            <br />
            <div style={{ margin: '5px 0' }}>
              <strong>{t('search_item')}:</strong>
              <Search
                itemPicking
                size="small"
              />
            </div>
          </>
        )}
      </>
    </Drawer>
  );
}

export default EditSideOption;
