import React, { useEffect, useState } from 'react';
import { useMount } from 'react-use';
import {
  Button,
  Switch,
  DatePicker,
  Select
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  dashboardPayloadAtom,
  reloadSalesTrendsAtom,
  reloadTenderTypesTrendsAtom,
  reloadCardTypesTrendsAtom,
  reloadCategoriesTrendsAtom,
  reloadItemsTrendsAtom,
  reloadLossTrendsAtom
} from '../../../../../atoms/Atoms';
import SalesTrends from './shared-components/SalesTrends';
import TenderTypeTrends from './shared-components/TenderTypeTrends';
import CardTypeTrends from './shared-components/CardTypeTrends';
import CategoriesTrends from './shared-components/CategoriesTrends';
import ItemsTrends from './shared-components/ItemsTrends';
import { dateRangePresets } from '../../../../utils/utils';
import LossTrends from './shared-components/LossTrends';

function Trends() {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [dashboardPayload, setDashboardPayload] =
    useRecoilState(dashboardPayloadAtom);
  const setReloadSalesTrends = useSetRecoilState(reloadSalesTrendsAtom);
  const setReloadTenderTypesTrends = useSetRecoilState(reloadTenderTypesTrendsAtom);
  const setReloadCardTypesTrends = useSetRecoilState(reloadCardTypesTrendsAtom);
  const setReloadCategoriesTrends = useSetRecoilState(reloadCategoriesTrendsAtom);
  const setReloadItemsTrends = useSetRecoilState(reloadItemsTrendsAtom);
  const setReloadLossTrends = useSetRecoilState(reloadLossTrendsAtom);
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    setFirstLoad(true);
    setDashboardPayload({
      start: moment().startOf('week').format('YYYY-MM-DD'),
      end: moment().endOf('week').format('YYYY-MM-DD'),
      compareLastYear: false,
      trendsType: 0
    });
  }, []);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      setReloadSalesTrends(true);
      setReloadTenderTypesTrends(true);
      setReloadCardTypesTrends(true);
      setReloadCategoriesTrends(true);
      setReloadItemsTrends(true);
      setReloadLossTrends(true);
    }
  }, [firstLoad, dashboardPayload]);

  return (
    <div>
      <PageHeader
        className="-mt-4 p-0"
        title={t('trends')}
        extra={[
          <div className="grid ml-8 md:ml-0 md:flex md:space-x-2 space-y-2">
            <Select
              className="my-auto pt-2"
              value={dashboardPayload?.trendsType}
              style={{ width: 160 }}
              onChange={(obj) => setDashboardPayload({
                ...dashboardPayload,
                trendsType: obj
              })}
            >
              <Option value={0}>{t('all_orders')}</Option>
              <Option value={1}>{t('webstore')}</Option>
              <Option value={2}>{t('pos')}</Option>
              <Option value={3}>{t('self_checkout')}</Option>
            </Select>
            <RangePicker
              format="YYYY-MM-DD"
              value={[
                dayjs(dashboardPayload?.start),
                dayjs(dashboardPayload?.end)
              ]}
              presets={dateRangePresets(t)}
              onChange={(date, dateString) => {
                setDashboardPayload({
                  ...dashboardPayload,
                  start: dateString[0],
                  end: dateString[1]
                });
              }}
            />
            <div className="mt-1 mx-auto md:mx-1">
              <span className="mt-2 md:ml-1">{t('compare_with_previous_year')}</span>
              <Switch
                className="md:ml-1"
                checked={dashboardPayload?.compareLastYear}
                onChange={(checked) => {
                  setDashboardPayload({
                    ...dashboardPayload,
                    compareLastYear: checked
                  });
                }}
              />
            </div>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                setFirstLoad(false);
                setReloadSalesTrends(true);
                setReloadTenderTypesTrends(true);
                setReloadCardTypesTrends(true);
                setReloadCategoriesTrends(true);
                setReloadItemsTrends(true);
              }}
            >
              {t('reload')}
            </Button>
          </div>
        ]}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-2">
        <SalesTrends className="col-span-1 md:col-span-2" />
        <TenderTypeTrends className="col-span-1 md:col-span-2 lg:col-span-1" />
        <CardTypeTrends className="col-span-1 md:col-span-2 lg:col-span-1" />
        <CategoriesTrends className="col-span-1 md:col-span-2 lg:col-span-1" />
        <ItemsTrends className="col-span-1 md:col-span-2 lg:col-span-1" />
        <LossTrends className="col-span-1 md:col-span-2" />
      </div>
    </div>
  );
}

export default Trends;
