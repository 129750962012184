import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useMount } from 'react-use';
import moment from 'moment';
import dayjs from 'dayjs';
import Text from 'antd/lib/typography/Text';

import {
  Table,
  Avatar,
  Tooltip,
  notification,
  Button,
  Switch,
  DatePicker,
  Spin,
  Typography,
  Row,
  Col
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { Line, Bar } from 'react-chartjs-2';
import { salesInsightsDateRangeAtom } from '../../../../../atoms/Atoms';
import api from '../../../../../api/api';
import Grocefy from '../../../../../assets/images/grocefyLogoAlone.png';

function Sales() {
  const { t } = useTranslation();
  const [summary, setSummary] = useState(null);
  const [salesInsightsDateRange, setSalesInsightsDateRange]
    = useRecoilState(salesInsightsDateRangeAtom);
  const [loading, setLoading] = useState(false);
  const [sales, setSales] = useState([]);
  const [ordersByDay, setOrdersByDay] = useState(null);
  const [reload, setReload] = useState(false);
  const [lifetimeSales, setLifetimeSales] = useState(null);
  const [totalOrders, setTotalOrders] = useState(0);
  const width = window.innerWidth;
  const [showDataLabels, setShowDatalabels] = useState(false);

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function numberWithCommas(x) {
    const val = x.toFixed(2);
    if (!val) {
      return 0;
    }
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  useMount(() => {
    setSalesInsightsDateRange({
      ...salesInsightsDateRange,
      lifetime: false
    });
  });

  function mapData(data) {
    const mapped = _.map(data, (p) => ({
      x: p.day,
      count: p.orderCount,
      sales: p.orderSales
    }));
    return _.sortBy(mapped, (s) => s.x, 'asc');
  }

  function mapLifetimeSales(data) {
    const mapped = _.map(data, (p) => ({
      x: p.businessName,
      y: p.orderSales,
    }));
    return _.sortBy(mapped, (s) => s.y, 'asc');
  }

  function getData() {
    if (salesInsightsDateRange != null) {
      setLoading(true);
      api.post('analytics/insights/sales/v2', salesInsightsDateRange)
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            const tempByDay = mapData(response.data.data.ordersByDay);
            setOrdersByDay(tempByDay);
            const newRow = {
              businessName: t('Total'),
              totalCustomers: 0,
              firstTimeCustomers: 0,
              recurringCustomers: 0,
              orderCount: 0,
              orderSales: 0,
              totalPaidOut: 0,
              webOrdersCount: 0,
              webOrdersAverage: 0,
              mobileOrdersCount: 0,
              mobileOrdersAverage: 0,
              inStoreOrdersCount: 0,
              inStoreOrdersPercentage: 0
            };
            for (let i = 0; i < response.data.data.sales.length; i++) {
              const temp = response.data.data.sales[i];
              newRow.totalCustomers += temp.totalCustomers;
              newRow.firstTimeCustomers += temp.firstTimeCustomers;
              newRow.recurringCustomers += temp.recurringCustomers;
              newRow.orderCount += temp.orderCount;
              newRow.orderSales += temp.orderSales;
              newRow.totalPaidOut += temp.totalPaidOut;
              newRow.webOrdersCount += temp.orderDeviceAnalytics.webOrdersCount;
              newRow.webOrderAverage += temp.orderDeviceAnalytics.webOrderAverage;
              newRow.mobileOrdersCount += temp.orderDeviceAnalytics.mobileOrdersCount;
              newRow.mobileOrderAverage += temp.orderDeviceAnalytics.mobileOrderAverage;
              newRow.inStoreOrdersPercentage += temp.orderDeviceAnalytics.inStoreOrdersPercentage;
              newRow.inStoreOrdersCount += temp.orderDeviceAnalytics.inStoreOrdersCount;
            }
            setSales([...response.data.data.sales]);
            setSummary(newRow);
            const sorted = _.orderBy(response.data.data.sales, (s) => s.orderCount);
            // console.log(sorted);
            setSales([...sorted]);
            setLifetimeSales(mapLifetimeSales(response.data.data.sales));
          } else {
            showMessage(response.data.message);
          }
        }).catch((err) => {
          setLoading(false);
          showMessage(err.message);
        });
    }
  }

  useEffect(() => {
    const temp = moment();
    const startDate = moment(`${temp.format('YYYY')}-${temp.format('M')}-1`, 'YYYY/MM/DD');
    const endDate = moment(`${temp.format('YYYY')}-${temp.format('M')}-1`, 'YYYY/MM/DD').add(1, 'months');
    setSalesInsightsDateRange({
      lifetime: false,
      startDate,
      endDate
    });
  }, []);

  useEffect(() => {
    if (salesInsightsDateRange) {
      getData();
    }
  }, [salesInsightsDateRange]);

  useEffect(() => {
    getData();
  }, [reload]);

  useEffect(() => {
    // console.log(width);
  }, [width]);
  const labels = _.map(ordersByDay, (s) => s.x);

  const graphData = {
    labels,
    datasets: [
      {
        label: t('count'),
        data: _.map(ordersByDay, (s) => s.count),
        backgroundColor: 'rgba(0, 204, 102, 0.2)',
        fill: true
      },
      {
        label: t('sales'),
        data: _.map(ordersByDay, (s) => s.sales),
        backgroundColor: 'rgba(53, 162, 253, 0.2)',
        fill: true
      },
    ],
  };

  const barGraphData = {
    labels: _.map(lifetimeSales, (s) => s.x),
    datasets: [
      {
        label: t('$'),
        data: _.map(lifetimeSales, (s) => s.y),
        backgroundColor: 'rgba(53, 162, 235, 0.2)',
      },
    ],
  };

  const barOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Lifetime Sales by Store',
      },
    },
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      }
    },
    responsive: true,
    plugins: {
      datalabels: {
        anchor: 'end',
        color: 'white',
        backgroundColor: '#a78bfa',
        borderRadius: '5',
        labels: {
          value: {}
        }
      },
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Daily Sales',
      },
    },
  };

  return (
    <div>
      <div className={width < 1024 ? null : 'hidden'}>
        {/* <Typography.Text type="primary" className="font-semibold text-xl">
          {t('today_sales')}
        </Typography.Text> */}
      </div>
      <br />
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('orders')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{summary && summary.orderCount.toLocaleString()}</dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('sales')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${summary && summary.orderSales.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</dd>
            </Spin>
          </div>
          <div className={summary && summary.orderCount > 0 ? 'px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6' : 'hidden'}>
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('average_ticket')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">${summary && (summary.orderSales / summary.orderCount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</dd>
            </Spin>
          </div>
          <div className={summary && ordersByDay && ordersByDay.length > 0 && summary.orderCount > 0 ? 'px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6' : 'hidden'}>
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('daily_orders')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{summary && (summary.orderCount / ordersByDay.length).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</dd>
            </Spin>
          </div>
        </dl>
      </div>
      <br />
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">{t('mobile')}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{summary && summary.mobileOrdersCount}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {summary && ((summary.mobileOrdersCount / summary.orderCount) * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">Web</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{summary && summary.webOrdersCount}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {summary && ((summary.webOrdersCount / summary.orderCount) * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin spinning={loading}>
              <dt className="text-sm font-medium text-gray-500 truncate">In-Store Sale</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{summary && summary.inStoreOrdersCount}
                <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" style={{ float: 'right', marginTop: '30px' }}>
                  {summary &&
                  ((summary.inStoreOrdersCount / summary?.orderCount) * 100).toFixed(2) }%
                </div>
              </dd>
            </Spin>
          </div>
        </dl>
      </div>
      <br />
      {salesInsightsDateRange?.lifetime ? <Typography.Text className="ml-8 text-lg font-medium text-gray-700">{t('sales_by_store')}</Typography.Text> : <Typography.Text className="ml-8 text-lg font-medium text-gray-700">{t('daily_orders')}</Typography.Text>}
      <br />
      {salesInsightsDateRange?.lifetime ?
        <div>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Bar
                height={250}
                data={barGraphData}
                options={barOptions}
              />
            </Col>
          </Row>
        </div> :
        <div>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Line
                height={250}
                data={graphData}
                options={options}
              />
            </Col>
          </Row>
        </div>}
      <br />
      <div className={width < 1024 ? 'hidden' : null}>
        <div className="flex mb-2 float-right">
          <DatePicker
            disabled={loading}
            className={salesInsightsDateRange?.lifetime ? 'hidden' : ''}
            value={dayjs(salesInsightsDateRange?.startDate)}
            onChange={(date, dateString) => {
              const elems = dateString.split('-');
              const startDate = moment(`${elems[0]}-${elems[1]}-1`, 'YYYY/MM/DD');
              const endDate = moment(`${elems[0]}-${elems[1]}-1`, 'YYYY/MM/DD').add(1, 'months');
              setSalesInsightsDateRange({
                ...salesInsightsDateRange,
                startDate,
                endDate
              });
            }}
            picker="month"
          />
          <Switch
            disabled={loading}
            checkedChildren={t('lifetime')}
            unCheckedChildren={t('lifetime')}
            className="mt-1 ml-2"
            onChange={(checked) => {
              setSalesInsightsDateRange({
                ...salesInsightsDateRange,
                lifetime: checked
              });
            }}
            checked={salesInsightsDateRange?.lifetime}
          />
          <Button
            id="reload"
            type="primary"
            key="reload"
            size="small"
            className="mt-1 ml-2"
            onClick={() => setReload(!reload)}
          >
            {t('reload')}
          </Button>
        </div>
        <br />
        <Table
          loading={loading}
          bordered
          pagination={false}
          dataSource={sales}
          scroll={{ x: 1000 }}
          columns={[
            {
              title: t('business'),
              dataIndex: 'businessName',
              key: 'businessName',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('customers'),
              dataIndex: 'totalCustomers',
              key: 'totalCustomers',
              align: 'center',
              className: 'text-xs',
              render: (text) => <span>{text.toLocaleString()}</span>,
            },
            {
              title: t('first_time_customers'),
              dataIndex: 'firstTimeCustomers',
              key: 'firstTimeCustomers',
              align: 'center',
              className: 'text-xs',
              render: (text) => <span>{text.toLocaleString()}</span>,
            },
            {
              title: t('recurring_customers'),
              dataIndex: 'recurringCustomers',
              key: 'recurringCustomers',
              align: 'center',
              className: 'text-xs',
              render: (text) => <span>{text.toLocaleString()}</span>,
            },
            {
              title: t('order_count'),
              dataIndex: 'orderCount',
              key: 'orderCount',
              align: 'right',
              width: 100,
              className: 'text-xs',
              render: (text) => <span>{`${text.toLocaleString()} (${((text / totalOrders) * 100).toFixed(0)}%)`}</span>,
              sorter: (a, b) => a.orderCount - b.orderCount
            },
            {
              title: t('sales'),
              dataIndex: 'orderSales',
              key: 'orderSales',
              align: 'center',
              className: 'text-xs',
              render: (text) => <span>${numberWithCommas(text)}</span>,
              sorter: (a, b) => a.orderSales - b.orderSales
            },
            {
              title: t('paid_out'),
              dataIndex: 'totalPaidOut',
              key: 'totalPaidOut',
              align: 'center',
              className: 'text-xs',
              render: (text) => <span>${numberWithCommas(text)}</span>,
              sorter: (a, b) => a.totalPaidOut - b.totalPaidOut

            }]}
          summary={(s) => {
            let totalCustomers = 0;
            let firstTimeCustomers = 0;
            let recurringCustomers = 0;
            let orderCount = 0;
            let orderSales = 0;
            let totalPaidOut = 0;

            s.forEach((p) => {
              totalCustomers += p.totalCustomers;
              firstTimeCustomers += p.firstTimeCustomers;
              recurringCustomers += p.recurringCustomers;
              orderCount += p.orderCount;
              orderSales += p.orderSales;
              totalPaidOut += p.totalPaidOut;
              setTotalOrders(orderCount);
            });
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    align="right"
                    className="text-sm font-bold"
                    colSpan={1}
                  >
                    Total
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="center">
                    <Text strong>
                      {totalCustomers
                        .toFixed()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="center">
                    <Text strong>
                      {firstTimeCustomers
                        .toFixed()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="center">
                    <Text strong>
                      {recurringCustomers
                        .toFixed()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="center">
                    <Text strong>
                      {orderCount
                        .toFixed(0)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="center">
                    <Text strong>
                      $
                      {orderSales
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="center">
                    <Text strong>
                      $
                      {totalPaidOut
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </div>
    </div>
  );
}

export default Sales;
