import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Card, Button, Row, Col, Table, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import { Line, Bar } from 'react-chartjs-2';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ReloadOutlined } from '@ant-design/icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  dashboardSelectedStoreAtom,
  dashboardPayloadAtom,
} from '../../../../atoms/Atoms';
import { numberWithCommas, percent, thousand, toCurrency } from '../../../utils/functions';
import api from '../../../../api/api';

function SalesAnalytics() {
  const [showDataLabels, setShowDatalabels] = useState(false);
  const { t } = useTranslation();
  const selectedStoreId = useRecoilValue(dashboardSelectedStoreAtom);
  const [dashboardPayload, setDashboardPayload] =
    useRecoilState(dashboardPayloadAtom);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const topRowGridStyle = { width: '100%', textAlign: 'center' };
  const bottomRowGridStyle = { width: '50%', textAlign: 'center' };
  const [totalOrders, setTotalOrders] = useState(0);

  function getTimeFactorDateLabel(factor) {
    if (factor === 7) {
      return t('hourly_orders');
    }
    if (factor === 0) {
      return t('daily_orders');
    }
    if (factor === 1) {
      return t('weekly_orders');
    }
    if (factor === 2) {
      return t('montly_orders');
    }
    if (factor === 3) {
      return t('yearly_orders');
    }
    return '';
  }

  function getData() {
    setLoading(true);
    api
      .post(`analytics/v2/insights/sales/${selectedStoreId}`, {
        Lifetime: dashboardPayload.isLifetime,
        StartDate: moment(dashboardPayload.start).utc(),
        EndDate: moment(dashboardPayload.end).utc().add(1, 'days'),
      })
      .then((response) => {
        setLoading(false);
        setData(response.data.data, 'dataLabel');
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    getData();
  }, [dashboardPayload.isLifetime, selectedStoreId]);

  useEffect(() => {
    if (dashboardPayload.load) {
      getData();
    }
  }, [dashboardPayload.load]);

  useEffect(() => {
    getData();
  }, [dashboardPayload]);

  function getValue(value, context) {
    return Math.round(value * 100);
  }

  return (
    <div>
      {/* Header */}
      <div className="flex">
        <div className="ml-auto flex">
          <Button
            type="primary"
            size="middle"
            icon={<ReloadOutlined />}
            className="ml-2"
            onClick={() => getData()}
          >
            {t('reload')}
          </Button>
        </div>
      </div>

      {/* Cards */}
      <div className="divide-y space-y-4">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900 mt-2">
            {t('revenue')}
          </h3>
          <dl className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
            <Card
              type="inner"
              title={t('all_services')}
              className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
              hoverable
              loading={loading}
            >
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('sales')}:</span>
                <span> ${thousand(data?.sales?.orderSales)}</span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('orders')}:</span>
                <span> {thousand(data?.sales?.orderCount)}</span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={topRowGridStyle}
              >
                <span className="font-bold">{t('average')}:</span>
                <span> ${thousand(data?.sales?.orderAverage)}</span>
              </Card.Grid>
            </Card>
            <Card
              type="inner"
              title={t('pickup')}
              className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
              hoverable
              loading={loading}
            >
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('sales')}:</span>
                <span> ${thousand(data?.sales?.pickupOrderSales)}</span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('orders')}:</span>
                <span> {thousand(data?.sales?.pickupOrderCount)}</span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('average')}:</span>
                <span> ${thousand(data?.sales?.pickupOrderAverage)}</span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('share')}: </span>
                <span>
                  {percent(data?.sales?.pickupOrderCount / data?.sales?.orderCount)}
                  %
                </span>
              </Card.Grid>
            </Card>
            <Card
              type="inner"
              title={t('delivery')}
              className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
              hoverable
              loading={loading}
            >
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('sales')}:</span>
                <span> ${thousand(data?.sales?.deliveryOrderSales)}</span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('orders')}:</span>
                <span> {thousand(data?.sales?.deliveryOrderCount)}</span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('average')}:</span>
                <span> ${thousand(data?.sales?.deliveryOrderAverage)}</span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold bg-white">{t('share')}: </span>
                <span>
                  {percent(data?.sales?.deliveryOrderCount / data?.sales?.orderCount)}
                  %
                </span>
              </Card.Grid>
            </Card>
            <Card
              type="inner"
              title={t('in_store')}
              className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
              hoverable
              loading={loading}
            >
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('sales')}:</span>
                <span> ${thousand(data?.sales?.inStoreOrderSales)}</span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('orders')}:</span>
                <span> {thousand(data?.sales?.inStoreOrderCount)}</span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('average')}:</span>
                <span> ${thousand(data?.sales?.inStoreOrderAverage)}</span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('share')}: </span>
                <span>
                  {percent(data?.sales?.inStoreOrderCount / data?.sales?.orderCount)}
                  %
                </span>
              </Card.Grid>
            </Card>
          </dl>
          <Spin spinning={loading}>
            <Row className="m-2">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Line
                  height={250}
                  data={{
                    labels: _.map(data?.orders, (s) => s.dateLabel),
                    datasets: [
                      {
                        label: `${t('sales_in_dollars')}($)`,
                        data: _.map(data?.orders, (s) =>
                          Math.round(s.orderSales)),
                        backgroundColor: 'rgba(53, 162, 253, 0.2)',
                        pointStyle: 'circle',
                        pointRadius: 6,
                        fill: true
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [{
                        ticks: {
                          beginAtZero: true,
                          callback: (value, index, ticks) => toCurrency(value)
                        }
                      }],
                    },
                    responsive: true,
                    plugins: {
                      datalabels: {
                        display: true,
                        offset: -30,
                        align: 'start',
                        color: 'white',
                        backgroundColor: '#38bdf8',
                        borderColor: 'blue',
                        borderRadius: 5,
                        formatter: (value, context) => toCurrency(value),
                        labels: {
                          title: {
                            font: {
                              weight: 'regular'
                            }
                          }
                        }
                      },
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: false,
                      },
                    },
                  }}
                />
              </Col>
            </Row>
          </Spin>
        </div>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900 mt-2">
            {t('devices')}
          </h3>
          <dl className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
            <Card
              type="inner"
              title={t('web_orders')}
              className="rounded-xl bg-gradient-to-br from-purple-200 to-white"
              hoverable
              loading={loading}
            >
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('sales')}:</span>
                <span>
                  {' '}
                  ${thousand(data?.sales?.orderDeviceAnalytics?.webOrderSales)}
                </span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('orders')}:</span>
                <span>
                  {' '}
                  {thousand(data?.sales?.orderDeviceAnalytics?.webOrdersCount)}
                </span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('average')}:</span>
                <span>
                  {' '}
                  $
                  {thousand(data?.sales?.orderDeviceAnalytics?.webOrderAverage)}
                </span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('share')}: </span>
                <span>
                  {(
                    data?.sales?.orderDeviceAnalytics?.webOrdersPercentage * 100
                  ).toFixed(2)}
                  %
                </span>
              </Card.Grid>
            </Card>
            <Card
              type="inner"
              title={t('mobile')}
              className="rounded-xl bg-gradient-to-br from-purple-200 to-white"
              hoverable
              loading={loading}
            >
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('sales')}: </span>
                <span>
                  $
                  {thousand(
                    data?.sales?.orderDeviceAnalytics?.androidOrderSales +
                      data?.sales?.orderDeviceAnalytics?.iOSOrderSales
                  )}
                </span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('orders')}:</span>
                <span>
                  {' '}
                  {thousand(
                    data?.sales?.orderDeviceAnalytics?.mobileOrdersCount
                  )}
                </span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('average')}:</span>
                <span>
                  {' '}
                  $
                  {thousand(
                    data?.sales?.orderDeviceAnalytics?.mobileOrderAverage
                  )}
                </span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('share')}: </span>
                <span>
                  {(
                    data?.sales?.orderDeviceAnalytics?.mobileOrdersPercentage *
                    100
                  ).toFixed(2)}
                  %
                </span>
              </Card.Grid>
            </Card>
            <Card
              type="inner"
              title={t('in_store')}
              className="rounded-xl bg-gradient-to-br from-purple-200 to-white"
              hoverable
              loading={loading}
            >
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('sales')}:</span>
                <span>
                  {' '}
                  $
                  {thousand(
                    data?.sales?.orderDeviceAnalytics?.inStoreOrderSales
                  )}
                </span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('orders')}:</span>
                <span>
                  {' '}
                  {thousand(
                    data?.sales?.orderDeviceAnalytics?.inStoreOrdersCount
                  )}
                </span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('average')}:</span>
                <span>
                  {' '}
                  $
                  {thousand(
                    data?.sales?.orderDeviceAnalytics?.inStoreOrderAverage
                  )}
                </span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('share')}: </span>
                <span>
                  {(
                    data?.sales?.orderDeviceAnalytics?.inStoreOrdersPercentage *
                    100
                  ).toFixed(2)}
                  %
                </span>
              </Card.Grid>
            </Card>
            <Card
              type="inner"
              title={t('other')}
              className="rounded-xl bg-gradient-to-br from-purple-200 to-white"
              hoverable
              loading={loading}
            >
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('sales')}: </span>
                <span>
                  $
                  {thousand(
                    data?.sales?.orderDeviceAnalytics?.unknownDeviceOrdersSales
                  )}
                </span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('orders')}: </span>
                <span>
                  {thousand(
                    data?.sales?.orderDeviceAnalytics?.unknownDeviceOrdersCount
                  )}
                </span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('average')}:</span>
                <span>
                  {' '}
                  $
                  {thousand(
                    data?.sales?.orderDeviceAnalytics?.unknownDeviceOrderAverage
                  )}
                </span>
              </Card.Grid>
              <Card.Grid
                className="bg-white"
                hoverable={false}
                style={bottomRowGridStyle}
              >
                <span className="font-bold">{t('share')}: </span>
                <span>
                  {(
                    data?.sales?.orderDeviceAnalytics
                      ?.unknownDeviceOrdersPercentage * 100
                  ).toFixed(2)}
                  %
                </span>
              </Card.Grid>
            </Card>
          </dl>
          <Spin spinning={loading}>
            <Row className="mt-4">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Bar
                  height={250}
                  data={{
                    labels: _.map(data?.orders, (s) => s.dateLabel),
                    datasets: [
                      {
                        label: t('orders'),
                        data: _.map(data?.orders, (s) => s.orderCount),
                        backgroundColor: 'rgba(216, 180, 254, 0.4)',
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [{
                        ticks: {
                          beginAtZero: true,
                          callback: (value, index, ticks) => toCurrency(value)
                        }
                      }],
                    },
                    responsive: true,
                    plugins: {
                      datalabels: {
                        anchor: 'end',
                        color: 'white',
                        backgroundColor: '#a78bfa',
                        borderRadius: '5',
                        formatter: (value, context) => toCurrency(value),
                        labels: {
                          title: {
                            font: {
                              weight: 'regular'
                            }
                          }
                        }
                      },
                      legend: {
                        position: 'top',
                      },
                      title: {
                        display: false,
                      },
                    },
                  }}
                />
              </Col>
            </Row>
          </Spin>
        </div>
        {data?.orders && data?.orders?.length > 0 && (
          <div>
            <div className="flex mt-4">
              <Card
                type="inner"
                title={getTimeFactorDateLabel(data?.ordersTimeFactor)}
                className="rounded-xl bg-gradient-to-br from-green-200 to-white"
                hoverable
                loading={loading}
              >
                <Card.Grid hoverable={false} style={bottomRowGridStyle}>
                  <span className="font-bold">{t('orders')}</span>
                  <br />
                  <span> {thousand(data?.averageCountPerTimeFactor)}</span>
                </Card.Grid>
                <Card.Grid hoverable={false} style={bottomRowGridStyle}>
                  <span className="font-bold">{t('sales')}</span>
                  <br />
                  <span> ${thousand(data?.averageSalePerTimeFactor)}</span>
                </Card.Grid>
              </Card>
            </div>
          </div>
        )}
        {selectedStoreId === '' && data && (
          <div>
            <Table
              loading={loading}
              bordered
              pagination={false}
              dataSource={data?.salesByBusiness}
              scroll={{ x: 1000 }}
              columns={[
                {
                  title: t('business'),
                  dataIndex: 'businessName',
                  key: 'businessName',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) => <span>{text}</span>,
                },
                {
                  title: t('customers'),
                  dataIndex: 'totalCustomers',
                  key: 'totalCustomers',
                  align: 'center',
                  className: 'text-xs',
                  render: (text) => <span>{text.toLocaleString()}</span>,
                },
                {
                  title: t('first_time_customers'),
                  dataIndex: 'firstTimeCustomers',
                  key: 'firstTimeCustomers',
                  align: 'center',
                  className: 'text-xs',
                  render: (text) => <span>{text.toLocaleString()}</span>,
                },
                {
                  title: t('recurring_customers'),
                  dataIndex: 'recurringCustomers',
                  key: 'recurringCustomers',
                  align: 'center',
                  className: 'text-xs',
                  render: (text) => <span>{text.toLocaleString()}</span>,
                },
                {
                  title: t('order_count'),
                  dataIndex: 'orderCount',
                  key: 'orderCount',
                  align: 'right',
                  width: 100,
                  className: 'text-xs',
                  render: (text) => (
                    <span>
                      {`${text.toLocaleString()} (${(
                        (text / totalOrders) *
                        100
                      ).toFixed(0)}%)`}
                    </span>
                  ),
                  sorter: (a, b) => a.orderCount - b.orderCount,
                },
                {
                  title: t('sales'),
                  dataIndex: 'orderSales',
                  key: 'orderSales',
                  align: 'center',
                  className: 'text-xs',
                  render: (text) => <span>${numberWithCommas(text)}</span>,
                  sorter: (a, b) => a.orderSales - b.orderSales,
                },
              ]}
              summary={(s) => {
                let totalCustomers = 0;
                let firstTimeCustomers = 0;
                let recurringCustomers = 0;
                let orderCount = 0;
                let orderSales = 0;
                let totalPaidOut = 0;

                s.forEach((p) => {
                  totalCustomers += p.totalCustomers;
                  firstTimeCustomers += p.firstTimeCustomers;
                  recurringCustomers += p.recurringCustomers;
                  orderCount += p.orderCount;
                  orderSales += p.orderSales;
                  totalPaidOut += p.totalPaidOut;
                  setTotalOrders(orderCount);
                });
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        align="right"
                        className="text-sm font-bold"
                        colSpan={1}
                      >
                        Total
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="center">
                        <Text strong>
                          {totalCustomers
                            .toFixed()
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="center">
                        <Text strong>
                          {firstTimeCustomers
                            .toFixed()
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="center">
                        <Text strong>
                          {recurringCustomers
                            .toFixed()
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="center">
                        <Text strong>
                          {orderCount
                            .toFixed(0)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="center">
                        <Text strong>
                          $
                          {orderSales
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default SalesAnalytics;
