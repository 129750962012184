import React, { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useJsonToCsv } from 'react-json-csv';
import {
  Tag,
  Row,
  Col,
  Button,
  Table,
  Typography,
  Select,
  DatePicker,
  Statistic,
  Card,
  Spin,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { CheckCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { businessAtom } from '../../../atoms/Atoms';
import { thousand, toCurrency } from '../../utils/functions';
import api from '../../../api/api';
import { dateRangePresets } from '../../utils/utils';

function StoreDepartmentsFinance() {
  const { RangePicker } = DatePicker;
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const { saveAsCsv } = useJsonToCsv();
  const business = useRecoilValue(businessAtom);
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedDepartmentKey, setSelectedDepartmentKey] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [allItems, setAllItems] = useState({
    orders: 0,
    itemCount: 0,
    items: [],
  });
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('day').format('YYYY-MM-DD'),
    end: moment().endOf('day').format('YYYY-MM-DD'),
  });
  const width = window.innerWidth;

  const paymentMethods = [
    { type: 'Stripe', value: 0 },
    { type: 'ATH Movil', value: 1 },
    { type: 'ATH', value: 7 },
    { type: 'Other', value: 3 },
    { type: 'EBT', value: 4 },
    { type: 'Paypal', value: 5 },
    { type: 'MCS', value: 10 },
    { type: 'MMM', value: 11 },
    { type: 'Selected', value: 15 },
    { type: 'Credit Card', value: 16 },
    { type: 'Debit Card', value: 17 },
    { type: 'SSS', value: 18 },
  ];

  const summaryListFields = {
    upc: 'Upc',
    item: t('item'),
    totalSales: t('total_sales'),
    priceTotal: t('price_total'),
    stateTaxTotal: t('state_tax_total'),
    municipalTaxTotal: t('municipal_tax_total'),
    price: t('average_price'),
    amount: t('amount'),
    department: t('department'),
    cost: t('average_cost'),
    profit: t('profit'),
    profitMargin: t('profit_margin'),
  };

  function mapSummaryData(data) {
    if (data) {
      const resultData = [];
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        resultData.push({
          upc: item.upc,
          item: `${item.brand} ${item.name} ${item.description}`,
          totalSales: item.totalSales,
          priceTotal: item.priceTotal,
          stateTaxTotal: item.stateTaxTotal,
          municipalTaxTotal: item.municipalTaxTotal,
          price: item.priceAverage,
          amount: item.amount,
          departmentNameEn: item.departmentNameEn,
          departmentNameEs: item.departmentNameEs,
          cost: item.cost,
          profit: item.profit,
          profitMargin: item.profitMargin * 100,
        });
        for (let j = 0; j < item.modifiers?.length; j++) {
          const modifier = item.modifiers[j];
          resultData.push({
            upc: '',
            item: modifier.name,
            totalSales: modifier.totalSales,
            priceTotal: modifier.priceTotal,
            stateTaxTotal: modifier.stateTaxTotal,
            municipalTaxTotal: modifier.municipalTaxTotal,
            price: modifier.priceAverage,
            amount: modifier.amount,
            department: '',
            cost: 0,
            profit: 0,
            profitMargin: 0,
          });
        }
      }
      return resultData;
    }
    return [];
  }

  function mapGroupedData(data) {
    if (data) {
      const mapped = _.map(data?.groupedResult, (p, index) => ({
        key: index,
        departmentNameEn: p.departmentNameEn,
        departmentNameEs: p.departmentNameEs,
        extended: p.extended,
        pct: (p.extended / data.totalExtended) * 100,
        profit: p.profit,
        profitMargin: p.profitMargin * 100,
      }));
      return _.sortBy(mapped, [(o) => o.departmentNameEn]);
    }
    return [];
  }

  function getData() {
    if (loading && hasInitialized) {
      return;
    }
    setHasInitialized(true);
    setLoading(true);
    const data = {
      StartDate: dateRange.start,
      EndDate: dateRange.end,
      BusinessId: business?.id,
      PaymentType: selectedPaymentMethod,
    };
    api
      .post('analytics/reporting/storedepartmentsales/withmodifiers/search', data)
      .then((response) => {
        setLoading(false);
        if (response.data.data) {
          setReportData(response.data.data);
          const temp = {
            orders: response.data.data.totalOrders,
            itemCount: response.data.data.itemCount,
            items: [],
          };
          response.data.data.groupedResult.forEach((element) => {
            temp.items = temp.items.concat(element.items);
          });
          setAllItems(temp);
          setSelectedDepartment(temp);
          setSelectedDepartmentKey(null);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (dateRange) {
      getData();
    }
  }, [dateRange, business, selectedPaymentMethod, hasInitialized]);

  useEffect(() => {
    setHasInitialized(true);
  }, []);

  function generatePDF() {
    // eslint-disable-next-line
    const doc = new jsPDF();
    const title = `${t('department_sales_report')} - ${
      business ? business.name : 'Global'
    }`;
    const headers = [[t('department'), t('extended'), t('profit'), t('profit_margin')]];

    let filteredData;
    if (selectedDepartmentKey !== null) {
      filteredData = reportData.groupedResult.filter(
        (item) => item.departmentId === selectedDepartmentKey
      );
    } else {
      filteredData = reportData.groupedResult;
    }

    const rows = filteredData.map((item) => [
      i18n.language === 'en' ? item.departmentNameEn : item.departmentNameEs || t('no_department'),
      toCurrency(item.extended),
      toCurrency(item.profit),
      `${(item.profitMargin * 100).toFixed(2)}%`,
    ]);

    doc.text(title, 20, 20);
    doc.autoTable({
      startY: 30,
      head: headers,
      body: rows,
    });

    filteredData.forEach((group, i) => {
      doc.addPage();
      doc.text(`${t('department')}: ${i18n.language === 'en' ? group.departmentNameEn : group.departmentNameEs || t('no_department')}`, 20, 20);
      const subHeaders = [
        [
          'Upc',
          t('item'),
          t('total_sales'),
          t('price_total'),
          t('state_tax_total'),
          t('municipal_tax_total'),
          t('average_price'),
          t('amount'),
          t('department'),
          t('average_cost'),
          t('profit'),
          t('profit_margin'),
        ],
      ];
      const subRows = group.items.flatMap((item) => {
        const baseRow = [
          item.upc,
          item.name || '',
          toCurrency(item.totalSales),
          toCurrency(item.priceTotal),
          toCurrency(item.stateTaxTotal),
          toCurrency(item.municipalTaxTotal),
          toCurrency(item.priceAverage),
          item.amount,
          item.department,
          toCurrency(item.cost || 0),
          toCurrency(item.profit || 0),
          `${(item.profitMargin * 100).toFixed(2)}%`,
        ];
        const modifierRows = item.modifiers.map((modifier) => [
          '', // Empty cells for UPC, Brand, Name, Description, etc.
          modifier.name,
          toCurrency(modifier.totalSales), // Empty cell for Total Sales
          toCurrency(modifier.priceTotal), // Empty cell for Price
          toCurrency(modifier.stateTaxTotal),
          toCurrency(modifier.municipalTaxTotal),
          toCurrency(modifier.priceAverage),
          modifier.amount,
          '', // Empty cell for Cost
          '',
        ]);
        return [baseRow, ...modifierRows];
      });

      doc.autoTable({
        startY: 30,
        head: subHeaders,
        body: subRows,
      });
    });

    doc.save('Store_Department_Sales_Report.pdf');
  }

  return (
    <div>
      <PageHeader
        className="mb-4 px-0"
        title={t('movement_by_department')}
        tags={
          <Tag color="#2db7f5" icon={<CheckCircleOutlined />}>
            {business ? business.name : t('global')}
          </Tag>
        }
        extra={[
          <RangePicker
            key="datepicker"
            disabled={loading}
            format="YYYY-MM-DD"
            value={[
              dayjs(dateRange.start, 'YYYY-MM-DD'),
              dayjs(dateRange.end, 'YYYY-MM-DD'),
            ]}
            presets={dateRangePresets(t)}
            onChange={(date, dateString) => {
              setDateRange({
                start: dateString[0],
                end: dateString[1],
              });
            }}
          />,
          <div key="select">
            <br className={width < 1024 ? null : 'hidden'} />
            <Select
              listHeight={500}
              style={{ width: 150 }}
              onChange={(value) => setSelectedPaymentMethod(value)}
              defaultValue={selectedPaymentMethod}
            >
              <Option key="all" value={null}>
                {t('all_payments')}
              </Option>
              {_.map(paymentMethods, (p) => (
                <Option
                  key={p.id}
                  value={p.value}
                  className="text-xs flex items-center"
                >
                  {p.type}
                </Option>
              ))}
            </Select>
          </div>,
          <Button type="primary" loading={loading} onClick={() => getData()}>
            {t('refresh')}
          </Button>,
        ]}
      />
      <div className="p-2 border rounded-lg mb-4">
        <div>
          <Row>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Card className="text-lg flex md:flex-col bg-purple-500 text-white">
                <Spin className="m-2" spinning={loading}>
                  <div className="text-xl">
                    {thousand(reportData?.itemCount)}
                  </div>
                  <div className="text-md">{t('products')}</div>
                </Spin>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Card className="text-lg flex md:flex-col bg-purple-500 text-white">
                <Spin className="m-2" spinning={loading}>
                  <div className="text-xl">{reportData?.totalOrders}</div>
                  <div className="text-md">{t('orders')}</div>
                </Spin>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Card className="text-lg flex md:flex-col bg-purple-500 text-white">
                <Spin className="m-2" spinning={loading}>
                  <div className="text-xl">
                    ${thousand(reportData?.totalExtended?.toFixed(2))}
                  </div>
                  <div className="text-md">{t('sales_products_total')}</div>
                </Spin>
              </Card>
            </Col>
          </Row>
        </div>
        <br />
        <br />
        <Table
          size="small"
          loading={loading}
          bordered
          scroll={{ x: 500 }}
          pagination={false}
          columns={[
            {
              title: t('department'),
              key: 'department',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>{i18n.language === 'en' ? text.departmentNameEn : text.departmentNameEs}</span>,
              sorter: (a, b) => (i18n.language === 'en' ? (a.departmentNameEn - b.departmentNameEn) : (a.departmentNameEs - b.departmentNameEs)),
            },
            {
              title: t('sales'),
              dataIndex: 'extended',
              key: 'extended',
              align: 'right',
              className: 'text-xs',
              render: (text) => <span> {toCurrency(text)}</span>,
              sorter: (a, b) => a.extended - b.extended,
            },
            {
              title: t('weight'),
              dataIndex: 'pct',
              key: 'pct',
              align: 'right',
              className: 'text-xs',
              render: (text) => (
                <span>
                  {' '}
                  {text
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  %
                </span>
              ),
              sorter: (a, b) => a.pct - b.pct,
            },
            {
              title: t('profit'),
              dataIndex: 'profit',
              key: 'profit',
              align: 'right',
              className: 'text-xs',
              render: (text) => <span> {toCurrency(text)}</span>,
              sorter: (a, b) => a.profit - b.profit,
            },
            {
              title: t('profit_margin'),
              dataIndex: 'profitMargin',
              key: 'profitMargin',
              align: 'right',
              width: 200,
              className: 'text-xs',
              render: (text) => (
                <span>
                  {' '}
                  {text
                    .toFixed(1)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  %
                </span>
              ),
              sorter: (a, b) => a.profitMargin - b.profitMargin,
            },
          ]}
          dataSource={mapGroupedData(reportData)}
        />
      </div>
      <div className="p-2 border bg-gray-100 rounded-lg mb-4">
        <Row align="middle" gutter={[8, 8]} justify="space-between">
          <Col>
            <Typography.Title level={5}>
              {t('department_sales')}
            </Typography.Title>
            <Typography.Text type="secondary">
              {t('department_sales_description')}
            </Typography.Text>
          </Col>
          <Col>
            <div className="flex flex-row">
              {' '}
              <div className="mr-10">
                <Statistic
                  title={t('orders')}
                  value={selectedDepartment?.orders}
                />
              </div>
              <div className="mr-10">
                <Statistic
                  title="Products"
                  value={selectedDepartment?.itemCount}
                />
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <Select
                listHeight={500}
                style={{ width: 150 }}
                onChange={(value) => {
                  setSelectedDepartmentKey(value);
                  if (value !== null) {
                    const temp = _.filter(
                      reportData?.groupedResult,
                      (d) => d.departmentId === value
                    );
                    setSelectedDepartment(temp[0]);
                  } else {
                    setSelectedDepartment(allItems);
                  }
                }}
                value={selectedDepartmentKey}
              >
                <Option
                  key="any"
                  value={null}
                  className="text-xs flex items-center"
                >
                  {t('all')}
                </Option>
                {_.map(_.sortBy(reportData?.groupedResult, (rd) => (i18n.language === 'en' ? rd.departmentNameEn : rd.departmentNameEs), 'asc'), (dept) => (
                  <Option
                    key={dept.departmentId}
                    value={dept.departmentId}
                    className="text-xs flex items-center"
                  >
                    {i18n.language === 'en' ? dept.departmentNameEn : dept.departmentNameEs}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col>
            <div className="flex space-x-2">
              <Button
                className="mb-4"
                size="small"
                disabled={!reportData}
                icon={<CloudDownloadOutlined />}
                onClick={() => {
                  generatePDF();
                }}
              >
                {t('export_pdf')}
              </Button>
              <Button
                className="mb-4"
                size="small"
                disabled={!reportData}
                icon={<CloudDownloadOutlined />}
                onClick={() => {
                  const data = mapSummaryData(selectedDepartment.items);
                  saveAsCsv({
                    data,
                    fields: summaryListFields,
                    filename: `reportData_${dateRange.start}_${dateRange.end}`,
                  });
                }}
              >
                {t('export_csv')}
              </Button>
            </div>
          </Col>
        </Row>
        <Table
          size="small"
          loading={loading}
          bordered
          rowKey="upc"
          expandable={{
            expandRowByClick: false,
            rowExpandable: (record) => record.modifiers?.length > 0,
            expandedRowRender: (record) => (
              <Table
                size="small"
                bordered
                rowKey="name"
                dataSource={record.modifiers}
                columns={[
                  {
                    title: t('modifier'),
                    dataIndex: 'name',
                    key: 'name',
                    align: 'right',
                    className: 'text-xs',
                    render: (text) => <span>{text}</span>,
                    sorter: (a, b) => a.name - b.name,
                  },
                  {
                    title: t('total_sales'),
                    dataIndex: 'totalSales',
                    key: 'totalSales',
                    align: 'center',
                    className: 'text-xs',
                    render: (text) => <span> {toCurrency(text)}</span>,
                    sorter: (a, b) => a.totalSales - b.totalSales,
                  },
                  {
                    title: t('price_total'),
                    dataIndex: 'priceTotal',
                    key: 'priceTotal',
                    align: 'right',
                    className: 'text-xs',
                    render: (text) => <span> {toCurrency(text)}</span>,
                    sorter: (a, b) => a.priceTotal - b.priceTotal,
                  },
                  {
                    title: t('state_tax_total'),
                    dataIndex: 'stateTaxTotal',
                    key: 'stateTaxTotal',
                    align: 'right',
                    className: 'text-xs',
                    render: (text) => <span> {toCurrency(text)}</span>,
                    sorter: (a, b) => a.stateTaxTotal - b.stateTaxTotal,
                  },
                  {
                    title: t('municipal_tax_total'),
                    dataIndex: 'municipalTaxTotal',
                    key: 'municipalTaxTotal',
                    align: 'right',
                    className: 'text-xs',
                    render: (text) => <span> {toCurrency(text)}</span>,
                    sorter: (a, b) => a.municipalTaxTotal - b.municipalTaxTotal,
                  },
                  {
                    title: t('average_price'),
                    dataIndex: 'priceAverage',
                    key: 'priceAverage',
                    align: 'right',
                    className: 'text-xs',
                    render: (text) => <span> {toCurrency(text)}</span>,
                    sorter: (a, b) => a.priceAverage - b.priceAverage,
                  },
                  {
                    title: t('amount'),
                    dataIndex: 'amount',
                    key: 'amount',
                    align: 'center',
                    className: 'text-xs',
                    render: (text) => <span>{thousand(text)}</span>,
                    sorter: (a, b) => a.amount - b.amount,
                  }
                ]}
              />
            ),
          }}
          scroll={{ x: 500 }}
          columns={[
            {
              title: 'Upc',
              dataIndex: 'upc',
              key: 'upc',
              align: 'right',
              className: 'text-xs',
              render: (text) => <span>{text}</span>,
              sorter: (a, b) => a.upc - b.upc,
            },
            {
              title: t('item'),
              key: 'name',
              align: 'right',
              className: 'text-xs',
              render: (text) => <span>{text.brand} {text.name} {text.description}</span>,
              sorter: (a, b) => `${a.brand} ${a.name} ${a.description}` - `${b.brand} ${b.name} ${b.description}`,
            },
            {
              title: t('total_sales'),
              dataIndex: 'totalSales',
              key: 'totalSales',
              align: 'center',
              className: 'text-xs',
              render: (text) => <span> {toCurrency(text)}</span>,
              sorter: (a, b) => a.totalSales - b.totalSales,
            },
            {
              title: t('price_total'),
              dataIndex: 'priceTotal',
              key: 'priceTotal',
              align: 'right',
              className: 'text-xs',
              render: (text) => <span> {toCurrency(text)}</span>,
              sorter: (a, b) => a.priceTotal - b.priceTotal,
            },
            {
              title: t('state_tax_total'),
              dataIndex: 'stateTaxTotal',
              key: 'stateTaxTotal',
              align: 'right',
              className: 'text-xs',
              render: (text) => <span> {toCurrency(text)}</span>,
              sorter: (a, b) => a.stateTaxTotal - b.stateTaxTotal,
            },
            {
              title: t('municipal_tax_total'),
              dataIndex: 'municipalTaxTotal',
              key: 'municipalTaxTotal',
              align: 'right',
              className: 'text-xs',
              render: (text) => <span> {toCurrency(text)}</span>,
              sorter: (a, b) => a.municipalTaxTotal - b.municipalTaxTotal,
            },
            {
              title: t('average_price'),
              dataIndex: 'priceAverage',
              key: 'priceAverage',
              align: 'right',
              className: 'text-xs',
              render: (text) => <span> {toCurrency(text)}</span>,
              sorter: (a, b) => a.priceAverage - b.priceAverage,
            },
            {
              title: t('amount'),
              dataIndex: 'amount',
              key: 'amount',
              align: 'center',
              className: 'text-xs',
              render: (text) => <span>{thousand(text)}</span>,
              sorter: (a, b) => a.amount - b.amount,
            },
            // {
            //   title: t('orders'),
            //   dataIndex: 'orderCount',
            //   key: 'orderCount',
            //   align: 'center',
            //   className: 'text-xs',
            //   render: (text) => <span>{thousand(text)}</span>,
            //   sorter: (a, b) => a.orderCount - b.orderCount,
            // },
            {
              title: t('department'),
              key: 'category',
              align: 'right',
              className: 'text-xs',
              render: (text) => <span>{i18n.language === 'en' ? text.departmentNameEn : text.departmentNameEs}</span>,
              sorter: (a, b) => (i18n.language === 'en' ? (a.departmentNameEn - b.departmentNameEn) : (a.departmentNameEs - b.departmentNameEs)),
            },
            {
              title: t('average_cost'),
              dataIndex: 'cost',
              key: 'cost',
              align: 'right',
              className: 'text-xs',
              render: (text) => <span>{toCurrency(text)}</span>,
              sorter: (a, b) => a.cost - b.cost,
            },
            {
              title: t('profit'),
              dataIndex: 'profit',
              key: 'profit',
              align: 'right',
              className: 'text-xs',
              render: (text) => <span>{toCurrency(text)}</span>,
              sorter: (a, b) => a.profit - b.profit,
            },
            {
              title: t('profit_margin'),
              dataIndex: 'profitMargin',
              key: 'profitMargin',
              align: 'right',
              width: 200,
              className: 'text-xs',
              render: (p) => (
                <span>
                  {' '}
                  {(p * 100)
                    .toFixed(1)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  %
                </span>
              ),
              sorter: (a, b) => a.profitMargin - b.profitMargin,
            },
          ]}
          dataSource={selectedDepartment?.items}
        />
      </div>
    </div>
  );
}

export default StoreDepartmentsFinance;
