import React, { useState, useEffect } from 'react';
import _, { head } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Divider,
  Typography,
  Card,
  Switch,
  Tabs,
  Button,
  Table,
  Pagination
} from 'antd';
import { CloudDownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { dashboardPayloadAtom, dashboardSelectedStoreAtom, reloadCustomerCards, timezoneAtom } from '../../../../atoms/Atoms';
import { mapGender, thousand } from '../../../utils/functions';
import api from '../../../../api/api';
import UserPurchaseProfile from '../../../shared-components/UserPurchaseProfile';
import CustomersCards from './CustomersCards';

function UserAnalytics() {
  const timezone = useRecoilValue(timezoneAtom);
  const topRowGridStyle = { width: '100%', textAlign: 'center' };
  const bottomRowGridStyle = { width: '33.3%', textAlign: 'center' };
  const { t } = useTranslation();
  const selectedStoreId = useRecoilValue(dashboardSelectedStoreAtom);
  const [dashboardPayload, setDashboardPayload] = useRecoilState(dashboardPayloadAtom);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('0');

  const [currentPage, setCurrentPage] = useState(0);
  const [currentSize, setCurrentSize] = useState(20);
  const [totalInPages, setTotalInPages] = useState(0);

  const [userAnalysisData, setUserAnalysisData] = useState(null);
  const [topCustomersData, setTopCustomersData] = useState(null);
  const [repeatCustomersData, setRepeatCustomersData] = useState(null);
  const [firstTimeCustomersData, setFirstTimeCustomersData] = useState(null);
  const [recentCustomersData, setRecentCustomersData] = useState(null);
  const [newCustomersData, setNewCustomersData] = useState(null);
  const [reload, setReload] = useRecoilState(reloadCustomerCards);
  const [loadingUserExport, setLoadingUserExport] = useState(false);

  const tableColumns = [
    {
      title: t('customer'),
      dataIndex: 'fullName',
      key: 'fullName',
      align: 'left',
      className: 'text-xs',
      render: (text) => <span>{text}</span>,
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
      align: 'left',
      className: 'text-xs',
      render: (text) => <span>{text}</span>,
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
      key: 'phone',
      align: 'left',
      className: 'text-xs',
      render: (text) => <span>{text}</span>,
    },
    {
      title: t('order_count'),
      dataIndex: 'orders',
      key: 'orders',
      align: 'center',
      className: 'text-xs',
      render: (text) => <span>{thousand(text)}</span>,
    },
    {
      title: t('total_spend'),
      dataIndex: 'totalSpend',
      key: 'totalSpend',
      align: 'center',
      className: 'text-xs',
      render: (text) => <span>${thousand(text)}</span>,
    },
    {
      title: t('average_purchase'),
      dataIndex: 'avg',
      key: 'avg',
      align: 'center',
      className: 'text-xs',
      render: (text, row) => <span>${thousand(row.totalSpend / row.orders)}</span>,
    },
    {
      title: t('first_purchase_date'),
      dataIndex: 'firstPurchaseDate',
      key: 'firstPurchaseDate',
      align: 'left',
      className: 'text-xs',
      render: (text) =>
        <span>
          {moment(`${text}+0000`)
            .tz(timezone)
            .format('MMM Do, YYYY')}
        </span>,
    },
    {
      title: t('last_purchase_date'),
      dataIndex: 'lastPurchaseDate',
      key: 'lastPurchaseDate',
      align: 'left',
      className: 'text-xs',
      render: (text) =>
        <span>
          {moment(`${text}+0000`)
            .tz(timezone)
            .format('MMM Do, YYYY')}
        </span>,
    },
    {
      title: t('registered_on'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'left',
      className: 'text-xs',
      render: (text) =>
        <span>
          {moment(`${text}+0000`)
            .tz(timezone)
            .format('MMM Do, YYYY')}
        </span>,
    },
    {
      title: t('purchase_profile'),
      key: 'purchaseProfile',
      align: 'left',
      className: 'text-xs',
      render: (row) =>
        <UserPurchaseProfile
          userId={row.id}
          businessId={selectedStoreId}
          lifetime={dashboardPayload.isLifetime}
          start={moment(dashboardPayload.start).utc()}
          end={moment(dashboardPayload.end).utc().add(1, 'days')}
        />,
    }
  ];

  function tablePagination() {
    return {
      pageSize: currentSize,
      showSizeChanger: true,
      current: currentPage + 1,
      total: totalInPages,
      onChange: async (p, pageSize) => {
        if (p - 1 !== currentPage) {
          await setCurrentPage(p - 1);
        } else {
          setCurrentSize(pageSize);
        }
      }
    };
  }

  function getUserAnalysisData() {
    setLoading(true);
    api.post(`analytics/v2/insights/customers/${selectedStoreId}`, {
      Lifetime: dashboardPayload.isLifetime,
      StartDate: moment(dashboardPayload.start).utc(),
      EndDate: moment(dashboardPayload.end).utc().add(1, 'days')
    }).then((response) => {
      setLoading(false);
      setUserAnalysisData([
        {
          count: response.data.data.totalUsers,
          gender: null,
          usersAge17Lower: response.data.data.usersAge17Lower,
          usersAge18to29: response.data.data.usersAge18to29,
          usersAge30to39: response.data.data.usersAge30to39,
          usersAge40to59: response.data.data.usersAge40to59,
          usersAge60Plus: response.data.data.usersAge60Plus,
          totalUsersWithAtleastOneCard: response.data.data.totalUsersWithAtleastOneCard,
          usersWithAtleastOneCardPercentage: response.data.data.usersWithAtleastOneCardPercentage,
          totalUsersWithAtleastOneOrder: response.data.data.totalUsersWithAtleastOneOrder,
          usersWithAtleastOneOrderPercentage: response.data.data.usersWithAtleastOneOrderPercentage,
          totalUsersWithAtleastOneLocation: response.data.data.totalUsersWithAtleastOneLocation,
          usersWithAtleastOneLocationPercentage:
            response.data.data.usersWithAtleastOneLocationPercentage,
        },
        ...response.data.data.genders
      ]);
    }).catch((error) => {
      console.error(error);
      setLoading(false);
    });
  }

  function getTopCustomersData() {
    setLoading(true);
    api.post(`analytics/v2/insights/topcustomers/${currentPage}/${currentSize}/${selectedStoreId}`, {
      Lifetime: dashboardPayload.isLifetime,
      StartDate: moment(dashboardPayload.start).utc(),
      EndDate: moment(dashboardPayload.end).utc().add(1, 'days')
    }).then((response) => {
      setLoading(false);
      setTopCustomersData(response.data.data);
      setTotalInPages(response.data.data.total);
    }).catch((error) => {
      console.error(error);
      setLoading(false);
    });
  }

  function getRepeatCustomersData() {
    setLoading(true);
    api.post(`analytics/v2/insights/repeatcustomers/${currentPage}/${currentSize}/${selectedStoreId}`, {
      Lifetime: dashboardPayload.isLifetime,
      StartDate: moment(dashboardPayload.start).utc(),
      EndDate: moment(dashboardPayload.end).utc().add(1, 'days')
    }).then((response) => {
      setLoading(false);
      setRepeatCustomersData(response.data.data);
      setTotalInPages(response.data.data.total);
    }).catch((error) => {
      console.error(error);
      setLoading(false);
    });
  }

  function getFirstTimeCustomersData() {
    setLoading(true);
    api.post(`analytics/v2/insights/firsttimecustomers/${currentPage}/${currentSize}/${selectedStoreId}`, {
      Lifetime: dashboardPayload.isLifetime,
      StartDate: moment(dashboardPayload.start).utc(),
      EndDate: moment(dashboardPayload.end).utc().add(1, 'days')
    }).then((response) => {
      setLoading(false);
      setFirstTimeCustomersData(response.data.data);
      setTotalInPages(response.data.data.total);
    }).catch((error) => {
      console.error(error);
      setLoading(false);
    });
  }

  function getRecentCustomersData() {
    setLoading(true);
    api.post(`analytics/v2/insights/recentcustomers/${currentPage}/${currentSize}/${selectedStoreId}`, {
      Lifetime: dashboardPayload.isLifetime,
      StartDate: moment(dashboardPayload.start).utc(),
      EndDate: moment(dashboardPayload.end).utc().add(1, 'days')
    }).then((response) => {
      setLoading(false);
      setRecentCustomersData(response.data.data);
      setTotalInPages(response.data.data.total);
    }).catch((error) => {
      console.error(error);
      setLoading(false);
    });
  }

  function getNewCustomersData() {
    setLoading(true);
    api.post(`analytics/v2/insights/newcustomers/${currentPage}/${currentSize}/${selectedStoreId}`, {
      Lifetime: dashboardPayload.isLifetime,
      StartDate: moment(dashboardPayload.start).utc(),
      EndDate: moment(dashboardPayload.end).utc().add(1, 'days')
    }).then((response) => {
      setLoading(false);
      setNewCustomersData(response.data.data);
      setTotalInPages(response.data.data.total);
    }).catch((error) => {
      console.error(error);
      setLoading(false);
    });
  }

  function exportTopUsers() {
    setLoadingUserExport(true);
    api.post('analytics/v2/insights/topcustomers/export', {
      Lifetime: dashboardPayload.isLifetime,
      StartDate: moment(dashboardPayload.start).utc(),
      EndDate: moment(dashboardPayload.end).utc().add(1, 'days')
    }).then((response) => {
      setLoadingUserExport(false);
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `top_users_${new Date().toLocaleDateString()}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }).catch((error) => {
      console.error(error);
      setLoadingUserExport(false);
    });
  }

  function exportRepeatCustomers() {
    setLoadingUserExport(true);
    api.post('analytics/v2/insights/repeatcustomers/export', {
      Lifetime: dashboardPayload.isLifetime,
      StartDate: moment(dashboardPayload.start).utc(),
      EndDate: moment(dashboardPayload.end).utc().add(1, 'days')
    }).then((response) => {
      setLoadingUserExport(false);
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `top_users_${new Date().toLocaleDateString()}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }).catch((error) => {
      console.error(error);
      setLoadingUserExport(false);
    });
  }

  function exportFirstTimeUsers() {
    setLoadingUserExport(true);
    api.post('analytics/v2/insights/firsttimecustomers/export', {
      Lifetime: dashboardPayload.isLifetime,
      StartDate: moment(dashboardPayload.start).utc(),
      EndDate: moment(dashboardPayload.end).utc().add(1, 'days')
    }).then((response) => {
      setLoadingUserExport(false);
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `first_time_users_${new Date().toLocaleDateString()}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }).catch((error) => {
      console.error(error);
      setLoadingUserExport(false);
    });
  }

  function exportRecentUsers() {
    setLoadingUserExport(true);
    api.post('analytics/v2/insights/recentcustomers/export', {
      Lifetime: dashboardPayload.isLifetime,
      StartDate: moment(dashboardPayload.start).utc(),
      EndDate: moment(dashboardPayload.end).utc().add(1, 'days')
    }).then((response) => {
      setLoadingUserExport(false);
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `recent_users_${new Date().toLocaleDateString()}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }).catch((error) => {
      console.error(error);
      setLoadingUserExport(false);
    });
  }

  function exportNewUsers() {
    setLoadingUserExport(true);
    api.post('analytics/v2/insights/newcustomers/export', {
      Lifetime: dashboardPayload.isLifetime,
      StartDate: moment(dashboardPayload.start).utc(),
      EndDate: moment(dashboardPayload.end).utc().add(1, 'days')
    }).then((response) => {
      setLoadingUserExport(false);
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `new_users_${new Date().toLocaleDateString()}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }).catch((error) => {
      console.error(error);
      setLoadingUserExport(false);
    });
  }

  function getData() {
    setDashboardPayload({
      ...dashboardPayload,
      load: false
    });
    if (selectedTab === '1') {
      getUserAnalysisData();
    }
    if (selectedTab === '0') {
      getTopCustomersData();
    }
    if (selectedTab === '2') {
      getRepeatCustomersData();
    }
    if (selectedTab === '3') {
      getFirstTimeCustomersData();
    }
    if (selectedTab === '4') {
      getRecentCustomersData();
    }
    if (selectedTab === '5') {
      getNewCustomersData();
    }
  }

  useEffect(() => {
    getData();
  }, [dashboardPayload.isLifetime, selectedStoreId]);

  useEffect(() => {
    setCurrentPage(0);
    setCurrentSize(20);
    setTotalInPages(0);
    setDashboardPayload({
      ...dashboardPayload,
      load: true
    });
  }, [selectedTab]);

  useEffect(() => {
    setDashboardPayload({
      ...dashboardPayload,
      load: true
    });
  }, [currentPage, currentSize]);

  useEffect(() => {
    if (dashboardPayload.load) {
      getData();
    }
  }, [dashboardPayload.load]);

  return (
    <div>
      {/* Header */}
      <div className="flex">
        <h3 className="flex-col text-lg leading-6 font-medium text-gray-900">{t('customer_analytics')}</h3>
        <div className="ml-auto flex">
          <Button
            type="primary"
            size="middle"
            icon={<ReloadOutlined />}
            onClick={() => { getData(); setReload(true); }}
          >
            {t('reload')}
          </Button>
        </div>
      </div>
      <br />
      <CustomersCards />
      <Tabs defaultActiveKey="0" onChange={(key) => setSelectedTab(key)}>
        <Tabs.TabPane tab={t('top_customers')} key="0">
          <div>
            <div className="float-right mr-2 mb-2">
              {t('showing')}: {thousand(totalInPages)}
              <Button
                size="small"
                type="primary"
                className="ml-2"
                icon={<CloudDownloadOutlined />}
                loading={loadingUserExport}
                disabled={loadingUserExport || totalInPages === 0}
                onClick={() => exportTopUsers()}
              >
                {t('export')}
              </Button>
            </div>
            <Table
              loading={loading}
              bordered
              pagination={tablePagination()}
              scroll={{ x: 1000 }}
              columns={tableColumns}
              dataSource={topCustomersData?.users}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('repeat_customers')} key="2">
          <div>
            <div className="float-right mr-2 mb-2">
              {t('showing')}: {thousand(totalInPages)}
              <Button
                size="small"
                type="primary"
                className="ml-2"
                icon={<CloudDownloadOutlined />}
                loading={loadingUserExport}
                disabled={loadingUserExport || totalInPages === 0}
                onClick={() => exportRepeatCustomers()}
              >
                {t('export')}
              </Button>
            </div>
            <Table
              loading={loading}
              bordered
              pagination={tablePagination()}
              scroll={{ x: 1000 }}
              columns={tableColumns}
              dataSource={repeatCustomersData?.users}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('first_time_customers')} key="3">
          <div>
            <div className="float-right mr-2 mb-2">
              {t('showing')}: {thousand(totalInPages)}
              <Button
                size="small"
                type="primary"
                className="ml-2"
                icon={<CloudDownloadOutlined />}
                loading={loadingUserExport}
                disabled={loadingUserExport || totalInPages === 0}
                onClick={() => exportFirstTimeUsers()}
              >
                {t('export')}
              </Button>
            </div>
            <Table
              loading={loading}
              bordered
              pagination={tablePagination()}
              scroll={{ x: 1000 }}
              columns={tableColumns}
              dataSource={firstTimeCustomersData?.users}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('recent_customers')} key="4">
          <div>
            <div className="float-right mr-2 mb-2">
              {t('showing')}: {thousand(totalInPages)}
              <Button
                size="small"
                type="primary"
                className="ml-2"
                icon={<CloudDownloadOutlined />}
                loading={loadingUserExport}
                disabled={loadingUserExport || totalInPages === 0}
                onClick={() => exportRecentUsers()}
              >
                {t('export')}
              </Button>
            </div>
            <Table
              loading={loading}
              bordered
              pagination={tablePagination()}
              scroll={{ x: 1000 }}
              columns={tableColumns}
              dataSource={recentCustomersData?.users}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('new_customers')} key="5">
          <div>
            <div className="float-right mr-2 mb-2">
              {t('showing')}: {thousand(totalInPages)}
              <Button
                size="small"
                type="primary"
                className="ml-2"
                icon={<CloudDownloadOutlined />}
                loading={loadingUserExport}
                disabled={loadingUserExport || totalInPages === 0}
                onClick={() => exportNewUsers()}
              >
                {t('export')}
              </Button>
            </div>
            <Table
              loading={loading}
              bordered
              pagination={tablePagination()}
              scroll={{ x: 1000 }}
              columns={tableColumns}
              dataSource={newCustomersData?.users}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('demographic_analysis')} key="1">
          <div className="divide-y space-y-4">
            <Tabs defaultActiveKey="overall">
              {_.map(userAnalysisData, (gender) => (
                <Tabs.TabPane
                  tab={gender.gender !== null ? t(mapGender(gender.gender)) : t('overall')}
                  key={gender.gender !== null ? gender.gender : 'overall'}
                >
                  <dl className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
                    <Card
                      title={t('overall')}
                      className="rounded-xl"
                      hoverable
                      loading={loading}
                    >
                      <Card.Grid
                        hoverable={false}
                        style={topRowGridStyle}
                      >
                        <span className="font-bold">{t('total')}:</span>
                        <span> {thousand(gender?.count)}</span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_card')}:</span><br />
                        <span> {thousand(gender?.totalUsersWithAtleastOneCard)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersWithAtleastOneCardPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_order')}:</span><br />
                        <span> {thousand(gender?.totalUsersWithAtleastOneOrder)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersWithAtleastOneOrderPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_location')}:</span><br />
                        <span> {thousand(gender?.totalUsersWithAtleastOneLocation)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersWithAtleastOneLocationPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                    </Card>
                    <Card
                      title={t('age_unknown')}
                      className="rounded-xl"
                      hoverable
                      loading={loading}
                    >
                      <Card.Grid
                        hoverable={false}
                        style={topRowGridStyle}
                      >
                        <span className="font-bold">{t('total')}:</span>
                        <span> {thousand(gender?.usersAge17Lower?.count)}</span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_card')}:</span><br />
                        <span> {thousand(gender?.usersAge17Lower?.totalUsersWithAtleastOneCard)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersAge17Lower?.usersWithAtleastOneCardPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_order')}:</span><br />
                        <span> {thousand(gender?.usersAge17Lower?.totalUsersWithAtleastOneOrder)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersAge17Lower?.usersWithAtleastOneOrderPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_location')}:</span><br />
                        <span> {thousand(gender?.usersAge17Lower?.totalUsersWithAtleastOneLocation)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersAge17Lower?.usersWithAtleastOneLocationPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                    </Card>

                    <Card
                      title={t('age_18_to_29')}
                      className="rounded-xl"
                      hoverable
                      loading={loading}
                    >
                      <Card.Grid
                        hoverable={false}
                        style={topRowGridStyle}
                      >
                        <span className="font-bold">{t('total')}:</span>
                        <span> {thousand(gender?.usersAge18to29?.count)}</span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_card')}:</span><br />
                        <span> {thousand(gender?.usersAge18to29?.totalUsersWithAtleastOneCard)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersAge18to29?.usersWithAtleastOneCardPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_order')}:</span><br />
                        <span> {thousand(gender?.usersAge18to29?.totalUsersWithAtleastOneOrder)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersAge18to29?.usersWithAtleastOneOrderPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_location')}:</span><br />
                        <span> {thousand(gender?.usersAge18to29?.totalUsersWithAtleastOneLocation)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersAge18to29?.usersWithAtleastOneLocationPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                    </Card>

                    <Card
                      title={t('age_30_to_39')}
                      className="rounded-xl"
                      hoverable
                      loading={loading}
                    >
                      <Card.Grid
                        hoverable={false}
                        style={topRowGridStyle}
                      >
                        <span className="font-bold">{t('total')}:</span>
                        <span> {thousand(gender?.usersAge30to39?.count)}</span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_card')}:</span><br />
                        <span> {thousand(gender?.usersAge30to39?.totalUsersWithAtleastOneCard)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersAge30to39?.usersWithAtleastOneCardPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_order')}:</span><br />
                        <span> {thousand(gender?.usersAge30to39?.totalUsersWithAtleastOneOrder)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersAge30to39?.usersWithAtleastOneOrderPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_location')}:</span><br />
                        <span> {thousand(gender?.usersAge30to39?.totalUsersWithAtleastOneLocation)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersAge30to39?.usersWithAtleastOneLocationPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                    </Card>

                    <Card
                      title={t('age_40_to_59')}
                      className="rounded-xl"
                      hoverable
                      loading={loading}
                    >
                      <Card.Grid
                        hoverable={false}
                        style={topRowGridStyle}
                      >
                        <span className="font-bold">{t('total')}:</span>
                        <span> {thousand(gender?.usersAge40to59?.count)}</span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_card')}:</span><br />
                        <span> {thousand(gender?.usersAge40to59?.totalUsersWithAtleastOneCard)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersAge40to59?.usersWithAtleastOneCardPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_order')}:</span><br />
                        <span> {thousand(gender?.usersAge40to59?.totalUsersWithAtleastOneOrder)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersAge40to59?.usersWithAtleastOneOrderPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_location')}:</span><br />
                        <span> {thousand(gender?.usersAge40to59?.totalUsersWithAtleastOneLocation)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersAge40to59?.usersWithAtleastOneLocationPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                    </Card>

                    <Card
                      title={t('age_60_plus')}
                      className="rounded-xl"
                      hoverable
                      loading={loading}
                    >
                      <Card.Grid
                        hoverable={false}
                        style={topRowGridStyle}
                      >
                        <span className="font-bold">{t('total')}:</span>
                        <span> {thousand(gender?.usersAge60Plus?.count)}</span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_card')}:</span><br />
                        <span> {thousand(gender?.usersAge60Plus?.totalUsersWithAtleastOneCard)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersAge60Plus?.usersWithAtleastOneCardPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_order')}:</span><br />
                        <span> {thousand(gender?.usersAge60Plus?.totalUsersWithAtleastOneOrder)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersAge60Plus?.usersWithAtleastOneOrderPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                      <Card.Grid
                        hoverable={false}
                        style={bottomRowGridStyle}
                      >
                        <span className="font-bold">{t('one_location')}:</span><br />
                        <span> {thousand(gender?.usersAge60Plus?.totalUsersWithAtleastOneLocation)} </span>
                        <span className="text-xs text-blue-500">
                          ({(gender?.usersAge60Plus?.usersWithAtleastOneLocationPercentage).toFixed(2)}%)
                        </span>
                      </Card.Grid>
                    </Card>
                  </dl>
                </Tabs.TabPane>
              ))}
            </Tabs>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default UserAnalytics;
