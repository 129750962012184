import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  Typography,
  Switch,
  Card,
  Select,
  DatePicker,
  Modal,
  Spin,
  List,
  Popover,
  Checkbox
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Bar } from 'react-chartjs-2';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
// import ZoomPlugin from 'chartjs-plugin-zoom';
import moment from 'moment';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useRecoilValue, useRecoilState, useRecoilValueLoadable } from 'recoil';
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  MinusOutlined,
  CloudDownloadOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useJsonToCsv } from 'react-json-csv';
import api from '../../../../../../api/api';
import {
  dashboardSelectedStoreAtom,
  dashboardPayloadAtom,
  reloadSalesTrendsAtom
} from '../../../../../../atoms/Atoms';
import {
  configSelector
} from '../../../../../../atoms/Selectors';
import { thousand, toCurrency, percent } from '../../../../../utils/functions';
import { dateRangePresets } from '../../../../../utils/utils';

function SalesTrends({ className }) {
  const { saveAsCsv } = useJsonToCsv();
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [reload, setReload] = useRecoilState(reloadSalesTrendsAtom);
  const selectedStoreId = useRecoilValue(dashboardSelectedStoreAtom);
  const [dashboardPayload, setDashboardPayload] =
    useRecoilState(dashboardPayloadAtom);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [dates, setDates] = useState({});
  const [compareLastYear, setCompareLastYear] = useState(false);
  const [trendsType, setTrendsType] = useState(0);
  const config = useRecoilValueLoadable(configSelector);
  const [tableColumns, setTableColumns] = useState([
    {
      title: t('date'),
      key: 'currentLabel',
      align: 'center',
      className: 'text-xs',
      render: (row) => <span>{row.currentLabel}</span>,
      sorter: (a, b) => a.currentLabel - b.currentLabel,
    },
    {
      title: t('orders'),
      key: 'currentOrders',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.currentOrders - b.currentOrders,
      render: (row) => (
        <div className="flex">
          <span>{thousand(row.currentOrders)}</span>
          {(!row.isFutureDate && row.ordersGrowth === 0) && (
            <MinusOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5"
              aria-hidden="true"
            />
          )}
          {(!row.isFutureDate && row.ordersGrowth > 0) && (
            <ArrowUpOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          )}
          {(!row.isFutureDate && row.ordersGrowth < 0) && (
            <ArrowDownOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
          {!row.isFutureDate && (
            <span className={`${row.ordersGrowth < 0 ? 'text-red-500' : ''} ${row.ordersGrowth > 0 ? 'text-green-500' : ''}`}>{thousand(percent(row.ordersGrowth))}%</span>
          )}
        </div>
      ),
    },
    {
      exportOnly: true,
      key: 'sold_growth',
      hidden: false,
      title: t('sales_total_growth')
    },
    {
      title: t('amount_collected'),
      key: 'currentAmountCollected',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.currentAmountCollected - b.currentAmountCollected,
      render: (row) => (
        <div className="flex">
          <span>{toCurrency(row.currentAmountCollected)}</span>
          {(!row.isFutureDate && row.amountCollectedGrowth === 0) && (
            <MinusOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5"
              aria-hidden="true"
            />
          )}
          {(!row.isFutureDate && row.amountCollectedGrowth > 0) && (
            <ArrowUpOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          )}
          {(!row.isFutureDate && row.amountCollectedGrowth < 0) && (
            <ArrowDownOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
          {!row.isFutureDate && (
            <span className={`${row.amountCollectedGrowth < 0 ? 'text-red-500' : ''} ${row.amountCollectedGrowth > 0 ? 'text-green-500' : ''}`}>{thousand(percent(row.amountCollectedGrowth))}%</span>
          )}
        </div>
      ),
    },
    {
      exportOnly: true,
      key: 'amountCollectedGrowth',
      hidden: false,
      title: t('amount_collected_growth')
    },
    {
      title: t('gross_sales'),
      key: 'currentGrossSales',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.currentGrossSales - b.currentGrossSales,
      render: (row) => (
        <div className="flex">
          <span>{toCurrency(row.currentGrossSales)}</span>
          {(!row.isFutureDate && row.grossSalesGrowth === 0) && (
            <MinusOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5"
              aria-hidden="true"
            />
          )}
          {(!row.isFutureDate && row.grossSalesGrowth > 0) && (
            <ArrowUpOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          )}
          {(!row.isFutureDate && row.grossSalesGrowth < 0) && (
            <ArrowDownOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
          {!row.isFutureDate && (
            <span className={`${row.grossSalesGrowth < 0 ? 'text-red-500' : ''} ${row.grossSalesGrowth > 0 ? 'text-green-500' : ''}`}>{thousand(percent(row.grossSalesGrowth))}%</span>
          )}
        </div>
      ),
    },
    {
      exportOnly: true,
      key: 'grossSalesGrowth',
      hidden: false,
      title: t('gross_sales_growth')
    },
    {
      title: t('net_sales'),
      key: 'currentNetSales',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.currentNetSales - b.currentNetSales,
      render: (row) => (
        <div className="flex">
          <span>{toCurrency(row.currentNetSales)}</span>
          {(!row.isFutureDate && row.netSalesGrowth === 0) && (
            <MinusOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5"
              aria-hidden="true"
            />
          )}
          {(!row.isFutureDate && row.netSalesGrowth > 0) && (
            <ArrowUpOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          )}
          {(!row.isFutureDate && row.netSalesGrowth < 0) && (
            <ArrowDownOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
          {!row.isFutureDate && (
            <span className={`${row.netSalesGrowth < 0 ? 'text-red-500' : ''} ${row.netSalesGrowth > 0 ? 'text-green-500' : ''}`}>{thousand(percent(row.netSalesGrowth))}%</span>
          )}
        </div>
      ),
    },
    {
      exportOnly: true,
      key: 'netSalesGrowth',
      hidden: false,
      title: t('net_sales_growth')
    },
    {
      title: t('average_ticket'),
      key: 'currentAverageTicket',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.currentAverageTicket - b.currentAverageTicket,
      render: (row) => (
        <div className="flex">
          <span>{toCurrency(row.currentAverageTicket)}</span>
          {(!row.isFutureDate && row.averageTicketGrowth === 0) && (
            <MinusOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5"
              aria-hidden="true"
            />
          )}
          {(!row.isFutureDate && row.averageTicketGrowth > 0) && (
            <ArrowUpOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          )}
          {(!row.isFutureDate && row.averageTicketGrowth < 0) && (
            <ArrowDownOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
          {!row.isFutureDate && (
            <span className={`${row.averageTicketGrowth < 0 ? 'text-red-500' : ''} ${row.averageTicketGrowth > 0 ? 'text-green-500' : ''}`}>{thousand(percent(row.averageTicketGrowth))}%</span>
          )}
        </div>
      ),
    },
    {
      exportOnly: true,
      key: 'averageTicketGrowth',
      hidden: false,
      title: t('average_ticket_growth')
    },
  ]);

  function toggleVisibility(key) {
    const tempColumns = [...tableColumns];
    for (let i = 0; i < tempColumns.length; i++) {
      if (tempColumns[i].key === key) {
        tempColumns[i].hidden = !tempColumns[i].hidden;
      }
    }
    setTableColumns(tempColumns);
  }

  function getData() {
    setLoading(true);
    api
      .post('analytics/v2/trends/sales', {
        CompareLastYear: compareLastYear,
        StartDate: moment(dates.start).utc(),
        EndDate: moment(dates.end).utc().add(1, 'days'),
        Type: trendsType,
        BusinessId: selectedStoreId
      })
      .then((response) => {
        setLoading(false);
        setData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      }).finally(() => {
        setReload(false);
      });
  }

  useEffect(() => {
    if (reload) {
      getData();
    }
  }, [reload]);

  useEffect(() => {
    getData();
  }, [selectedStoreId]);

  useEffect(() => {
    setCompareLastYear(dashboardPayload.compareLastYear);
    setTrendsType(dashboardPayload.trendsType);
  }, [dashboardPayload.compareLastYear, dashboardPayload.trendsType]);

  useEffect(() => {
    const temp = {
      start: dashboardPayload.start,
      end: dashboardPayload.end
    };
    setDates(temp);
  }, [dashboardPayload.start, dashboardPayload.end]);

  useEffect(() => {
    const tempColumns = [...tableColumns];
    for (let i = 0; i < tempColumns.length; i++) {
      tempColumns[i].hidden = false;
    }
    setTableColumns(tempColumns);
  }, [showModal]);

  return (
    <Card
      className={`shadow-lg ${className}`}
      hoverable
    >
      <Spin spinning={loading}>
        <PageHeader
          className="-mt-4 p-0"
          title={t('sales')}
          extra={[
            <Button
              type="primary"
              size="small"
              onClick={() => {
                getData();
              }}
            >
              {t('reload')}
            </Button>
          ]}
        />
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-2">
          <div className="col-span-1 lg:col-span-7">
            <Bar
              data={{
                labels: _.map(data?.comparisons, (s) => (s.isGrouped && !compareLastYear ? s.currentLabel : `${s.previousLabel} : ${s.currentLabel}`)),
                datasets: [
                  {
                    label: data?.previousLabel,
                    borderColor: config?.contents?.data?.secondaryColor,
                    backgroundColor: `${config?.contents?.data?.secondaryColor}cc`,
                    data: _.map(data?.comparisons, (x) => x.previousNetSales),
                  },
                  {
                    label: data?.currentLabel,
                    borderColor: config?.contents?.data?.primaryColor,
                    backgroundColor: `${config?.contents?.data?.primaryColor}cc`,
                    data: _.map(data?.comparisons, (x) => x.currentNetSales),
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                scales: {
                  yAxes: [{
                    ticks: {
                      beginAtZero: true,
                      callback: (value, index, ticks) => toCurrency(value)
                    }
                  }],
                },
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  title: {
                    display: false,
                  },
                  datalabels: {
                    anchor: 'end',
                    offset: 8,
                    rotation: 330,
                    color: 'black',
                    formatter: (value, context) => toCurrency(value),
                    labels: {
                      title: {
                        font: {
                          weight: 'regular'
                        }
                      }
                    }
                  }
                },
              }}
            />
          </div>
          <div className="col-span-1 lg:col-span-5">
            <List
              bordered
              dataSource={[
                { name: t('orders'), currentValue: data?.currentOrders, previousValue: data?.previousOrders, growth: data?.ordersGrowth, isNumber: true },
                { name: t('gross_sales'), currentValue: data?.currentGrossSales, previousValue: data?.previousGrossSales, growth: data?.grossSalesGrowth },
                { name: t('net_sales'), currentValue: data?.currentNetSales, previousValue: data?.previousNetSales, growth: data?.netSalesGrowth },
                { name: t('average_ticket'), currentValue: data?.currentAverageTicket, previousValue: data?.previousAverageTicket, growth: data?.averageTicketGrowth },
                { name: t('amount_collected'), currentValue: data?.currentAmountCollected, previousValue: data?.previousAmountCollected, growth: data?.amountCollectedGrowth }
              ]}
              renderItem={(item) => (
                <List.Item hoverable className="hover:scale-1.5">
                  <Typography.Text
                    className="w-full flex hover:scale-1.5"
                  >
                    <span className="text-xl font-bold">{item.name}</span>
                    <div className="ml-auto">
                      <div className="text-xl ml-auto text-right">{item.isNumber ? thousand(item.currentValue) : toCurrency(item.currentValue)}</div>
                      <div className="space-x-2 flex">
                        {item.growth === 0 && (
                          <MinusOutlined
                            className="mt-1 -mr-2 flex-shrink-0 self-center h-5 w-5"
                            aria-hidden="true"
                          />
                        )}
                        {item.growth > 0 && (
                          <ArrowUpOutlined
                            className="mt-1 -mr-2 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                        )}
                        {item.growth < 0 && (
                          <ArrowDownOutlined
                            className="mt-1 -mr-2 flex-shrink-0 self-center h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        )}
                        <span className={`text-lg ml-auto ${item.growth < 0 ? 'text-red-500' : ''} ${item.growth > 0 ? 'text-green-500' : ''}`}>{thousand(percent(item.growth))}%</span>
                        <span className="text-lg ml-auto">({item.isNumber ? thousand(item.previousValue) : toCurrency(item.previousValue)})</span>
                      </div>
                    </div>
                  </Typography.Text>
                </List.Item>
              )}
            />
          </div>
        </div>
        <Button
          className="-mb-4 float-right text-blue-500 border-none"
          onClick={() => setShowModal(true)}
        >
          <span className="underline">{t('view_details')}</span>
        </Button>
      </Spin>
      <Modal
        title={t('sales')}
        open={showModal}
        centered
        width="85%"
        onCancel={() => setShowModal(false)}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            onClick={() => setShowModal(false)}
          >
            {t('close')}
          </Button>
        ]}
      >
        <div className="space-y-4">
          <div className="grid md:flex md:space-x-2 space-y-2">
            <Select
              className="ml-auto my-auto pt-2"
              value={trendsType}
              style={{ width: 160 }}
              onChange={(obj) => setTrendsType(obj)}
            >
              <Option value={0}>{t('all_orders')}</Option>
              <Option value={1}>{t('webstore')}</Option>
              <Option value={2}>{t('pos')}</Option>
              <Option value={3}>{t('self_checkout')}</Option>
            </Select>
            <RangePicker
              format="YYYY-MM-DD"
              value={[
                dayjs(dates?.start),
                dayjs(dates?.end)
              ]}
              presets={dateRangePresets(t)}
              onChange={(date, dateString) => {
                const temp = {
                  start: dateString[0],
                  end: dateString[1]
                };
                setDates(temp);
              }}
            />
            <div className="mt-1 mx-auto md:mx-1">
              <span className="mt-2 md:ml-1">{t('compare_with_previous_year')}</span>
              <Switch
                className="md:ml-1"
                checked={compareLastYear}
                onChange={(checked) => setCompareLastYear(checked)}
              />
            </div>
            <Button
              type="primary"
              size="small"
              loading={loading}
              onClick={() => getData()}
            >
              {t('reload')}
            </Button>
          </div>
          <Spin spinning={loading}>
            <div style={{ height: 300 }}>
              <Bar
                data={{
                  labels: _.map(data?.comparisons, (s) => (s.isGrouped && !compareLastYear ? s.currentLabel : `${s.previousLabel} : ${s.currentLabel}`)),
                  datasets: [
                    {
                      label: data?.previousLabel,
                      borderColor: config?.contents?.data?.secondaryColor,
                      backgroundColor: `${config?.contents?.data?.secondaryColor}cc`,
                      data: _.map(data?.comparisons, (x) => x.previousNetSales),
                    },
                    {
                      label: data?.currentLabel,
                      borderColor: config?.contents?.data?.primaryColor,
                      backgroundColor: `${config?.contents?.data?.primaryColor}cc`,
                      data: _.map(data?.comparisons, (x) => x.currentNetSales),
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [{
                      ticks: {
                        beginAtZero: true,
                        callback: (value, index, ticks) => toCurrency(value)
                      }
                    }],
                  },
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: false,
                    },
                    zoom: {
                      pan: {
                        enabled: true,
                        mode: 'x',
                        speed: 20,
                        threshold: 0,
                        onPan: (chart) => console.log('I\'m panning!!!'),
                        onPanComplete: (chart) => console.log('I was panned!!!')
                      },
                      zoom: {
                        enabled: true,
                        drag: true,
                        mode: 'x',
                        speed: 1,
                        threshold: 0,
                        sensitivity: 0,
                        onZoom: (chart) => console.log('I\'m zooming!!!'),
                        onZoomComplete: (chart) => console.log('I was zoomed!!!')
                      }
                    },
                    datalabels: {
                      anchor: 'end',
                      offset: 8,
                      rotation: 330,
                      color: 'black',
                      formatter: (value, context) => toCurrency(value),
                      labels: {
                        title: {
                          font: {
                            weight: 'regular'
                          }
                        }
                      }
                    }
                  },
                }}
              />
            </div>
            <Table
              bordered
              size="small"
              dataSource={data?.comparisons}
              columns={_.filter(tableColumns, (c) => !c.exportOnly)}
              title={() => (
                <div className="flex">
                  <Popover
                    className=""
                    trigger="click"
                    content={(
                      <div className="grid grid-cols-4 gap-4">
                        {_.map(_.filter(tableColumns, (c) => !c.exportOnly), (c) => (
                          <Checkbox
                            checked={!c.hidden}
                            onChange={(value) => toggleVisibility(c.key)}
                          >
                            {c.title}
                          </Checkbox>
                        ))}
                      </div>
                    )}
                  >
                    <Button className="ml-auto font-bold text-blue-500 hover:text-blue-800 border-none bg-transparent">
                      {t('show_hide_columns')}
                    </Button>
                  </Popover>
                  <Button
                    className="mt-1"
                    size="small"
                    icon={<CloudDownloadOutlined />}
                    onClick={() => {
                      const shownColumns = _.filter(tableColumns, (c) => !c.hidden);
                      const tempFields = { };
                      for (let i = 0; i < shownColumns.length; i++) {
                        tempFields[shownColumns[i].key] = shownColumns[i].title;
                      }
                      saveAsCsv({
                        data: _.filter(data.comparisons, (c) => !c.hidden),
                        fields: tempFields,
                        filename: `saleTrends_${dates.start}_${dates.end}`,
                      });
                    }}
                  >
                    {t('export_csv')}
                  </Button>
                </div>
              )}
            />
          </Spin>
        </div>
      </Modal>
    </Card>
  );
}

export default SalesTrends;
